<template>
  <div class="container-xxl flex-grow-1 container-p-y text-start">
    <h4 class="py-3 mb-4">
      <span class="text-muted fw-light">User /</span> Management
    </h4>
    <div class="row">
      <div class="col-md-12">
        <ProgressBar v-if="loading" mode="indeterminate" style="height: 2px"></ProgressBar>
        <div class="nav-align-top mb-4">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item" role="presentation">
              <button type="button" :class=getTabClass(0) @click="changeTab(0)" role="tab" data-bs-toggle="tab" data-bs-target="#navs-top-home" aria-controls="navs-top-home" aria-selected="false" tabindex="-1">
                Data Table
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button type="button" :class=getTabClass(1) @click="changeTab(1)" role="tab" data-bs-toggle="tab" data-bs-target="#navs-top-profile" aria-controls="navs-top-profile" aria-selected="false" tabindex="-1">
                Add User
              </button>
            </li>
          </ul>
          <div class="tab-content">
            <div :class="getTabContentClass(0)" id="navs-top-home" role="tabpanel">
              <div class="card-datatable table-responsive">
              </div>
            </div>
            <div :class="getTabContentClass(1)" id="navs-top-profile" role="tabpanel">
              <div class="col-xl">
                <div class="card mb-4">
                  <div class="card-header d-flex justify-content-between align-items-center">
                    <h5 class="mb-0">Add User</h5>
                    <small class="text-muted float-end">Form user</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex"
import ProgressBar from 'primevue/progressbar'
import {computed, ref} from "vue";
export default {
  name: "UserManagement",
  components:{ ProgressBar},
  setup(){
    const store = useStore()
    let tabactive =  ref(0)

    function changeTab(idx){
      tabactive.value = idx;
    }

    function getTabClass(idx){
      if(tabactive.value === idx){
        return "nav-link active"
      }else{
        return "nav-link"
      }
    }

    function getTabContentClass(idx){
      if(tabactive.value === idx){
        return "tab-pane active show"
      }else{
        return "tab-pane fade";
      }
    }
    return{
      loading: computed(()=>store.getters.getLoadingStatus),
      getTabClass,
      getTabContentClass,
      changeTab
    }
  }
}
</script>

<style scoped>

</style>