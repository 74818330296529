<template>
  <div class="col-lg-6 col-md-12 col-6 mb-4">
    <div class="card">
      <div class="card-body">
        <div class="card-title d-flex align-items-start justify-content-between">
          <div class="avatar flex-shrink-0">
            <img
                :src=icon
                alt="chart success"
                class="rounded" />
          </div>
        </div>
        <span class="fw-medium d-block mb-1">{{title}}</span>
        <h3 class="card-title mb-2 fs-6">{{value}}</h3>
        <small class="text-success fw-medium"><i class="bx bx-up-arrow-alt"></i> {{subinfo}}</small>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";

export default {
  name: "SquareBox",
  props:{
    icon: String,
    title: String,
    value: String,
    subinfo: String
  },
  setup(){
    let menuclass = ref('');
    let showmenu = ref(false);

    function toggleShowMenu(){
      showmenu.value =!showmenu.value;
      if(!showmenu.value){
        menuclass.value = '';
      }else{
        menuclass.value = 'show';
      }
    }
    return{
      menuclass,
      toggleShowMenu
    }
  }
}
</script>

<style scoped>

</style>