<template>
  <li class="menu-item" @click="checkClick(title,isparent)" :class="activeClass">
    <RouterLink
        style="cursor: pointer"
        :to=linkto
        target=_self
        :class=dropClass
        @click="dropClick">
      <i :class="iconClass"></i>
      <div :data-i18n=title>{{title}}</div>
      <div v-if="badge>0 && badgetype==1" class="badge bg-danger rounded-pill ms-auto">{{badge}}</div>
      <div v-if="badge>0  && badgetype==0" class="badge bg-label-primary fs-tiny rounded-pill ms-auto">
        {{badge}}
      </div>
    </RouterLink>
    <ul class="menu-sub" v-if="menuchild.length>0">
      <MenuItem v-for="(child,x) in childList" :key="x"
                :target="child.target"
                :linkto="child.linkto"
                :title="child.title"
                :badge="child.badge"
                :badgetype="child.badgetype"
                :menuchild="[]"
                :isparent="child.isparent"
                :icon="child.icon"
                :activemenu="childActive"
                @activeChange="activeChange"
      ></MenuItem>
    </ul>
  </li>
</template>

<script>
import {computed, onMounted, ref} from "vue";

export default {
  name: "MenuItem",
  props:{
    target:String,
    linkto:String,
    title:String,
    badge:String,
    activemenu:String,
    icon: String,
    badgetype: Number,
    menuchild: Array,
    isparent: Boolean
  },
  setup(props,{emit}){
    let childList = ref([]);
    let activeClass = ref();
    let iconClass = ref();
    let dropClass = ref();
    let childActive = ref();
    let toggleOpen = ref(false);
    for (const i in props.menuchild) {
      childList.value.push(props.menuchild[i]);
    }
    if(props.menuchild.length>0){
      dropClass = 'menu-link menu-toggle';
    }else{
      dropClass = 'menu-link'
    }
    if(props.icon.length>0){
      iconClass = 'menu-icon '+props.icon;
    }else {
      iconClass = 'menu-icon';
    }
    activeClass = computed(() => {
      if(props.activemenu===props.title){
        return  'active open';
      }else{
        return '';
      }
    })
    onMounted(()=>{
      iconClass = '';
    });
    function checkClick(t,p) {
      if(p){
        emit('activeChange',t);
      }else{
        childActive = t;
        emit('activeChange',t);
      }
    }
    function dropClick(){
      toggleOpen.value = !toggleOpen.value;
      if(childActive.value != null){
        childActive.value = null;
      }
    }
    function activeChange(param){
      childActive.value = param;
    }
    function changeChild(param){
      childActive.value = param;
    }
    return {
      activeClass,
      iconClass,
      dropClass,
      checkClick,
      dropClick,
      toggleOpen,
      childActive,
      childList,
      changeChild,
      activeChange
    }
  }
}
</script>

<style scoped>

</style>