import axios from 'axios';
import authHeader from "@/services/auth.header";
const API_URL = process.env.VUE_APP_BASE_URL;
//const API_TOKEN = process.env.TOKEN_NAME;
class DashboardServices {
    getData(data,path){
        return axios.get(API_URL + path, data, {headers: authHeader()});
    }
    postData(data,path){
        return axios.post(API_URL + path, data, {headers: authHeader()})
    }
    postLogin(data,path){
        return axios.post(API_URL + path, data, {})
    }
    generateUrl(path){
        return API_URL+path;
    }
    getUserToken(){
        let user = JSON.parse(localStorage.getItem('broadcastuser'))
        return user.data.token
    }
}

export default new DashboardServices();