<template>
  <div class="input-group">
    <span class="input-group-text" id="basic-addon11">{{label}}</span>
    <input :type="type" :value="modelValue" :maxlength="maxlength"  @input="setData" class="form-control" :placeholder="placeholder" :aria-label="label" aria-describedby="basic-addon11">
  </div>
</template>

<script>

export default {
  name: "InputTextGroup",
  props:{
    label:String,
    placeholder: String,
    type:String,
    modelValue: String,
    maxlength:{type:String,required:true}
  },
  setup(props,{emit}){
    function setData(v) {
      emit('update:modelValue',v.target.value)
    }
    return{
      setData
    }
  }
}
</script>

<style scoped>

</style>