<template>
  <div class="container-xxl flex-grow-1 container-p-y">
    <div class="row">
      <AnnounceBar title="Hi.." texthtml='Welcome'></AnnounceBar>
      <div class="col-lg-4 col-md-4 order-1">
        <div class="row">
          <SquareBox :icon="require(`@/assets/img/icons/unicons/chart-success.png`)"
                     title="Periode"
                     :value="dataDashboard.data.periode"
                     subinfo=""></SquareBox>
          <SquareBox :icon="require(`@/assets/img/icons/unicons/wallet-info.png`)"
                     title="Balance"
                     :value="dataDashboard.data.balance"
                     subinfo=""></SquareBox>
        </div>
      </div>
    </div>
    <div class="row">
      <TrxComponentBox v-if="!dataLastTrx.loading" :data="dataLastTrx.data" :boxtitle="dataLastTrx.message"></TrxComponentBox>
      <ProgressSpinner v-else style="width: 50px; height: 50px" strokeWidth="8" fill="transparent"
                       animationDuration=".5s" aria-label="Custom ProgressSpinner" />
      <div class="col-md-6">
        <div class="card-header d-flex align-items-center justify-content-between">
          <h5 class="card-title m-0 me-2">Chart</h5>
        </div>
        <div class="card-body">
          <Chart v-if="!dataChart.loading" type="bar" :data="chartData" :options="chartOptions" />
          <ProgressSpinner v-else style="width: 50px; height: 50px" strokeWidth="8" fill="transparent"
                           animationDuration=".5s" aria-label="Custom ProgressSpinner" />
        </div>
      </div>
    </div>
    <div class="row">
    </div>
  </div>
</template>

<script>
import ProgressSpinner from 'primevue/progressspinner';
import Chart from 'primevue/chart';
import SquareBox from "@/components/widget/SquareBox";
import AnnounceBar from "@/components/widget/AnnounceBar";
import {useStore} from "vuex";
import {computed, onMounted, ref} from "vue";
import DashboardServices from "@/services/dashboard.services";
import TrxComponentBox from "@/components/trxbox/TrxComponentBox";
export default {
  name: "LandingPages",
  components: {TrxComponentBox, AnnounceBar, SquareBox,Chart,ProgressSpinner},
  setup(){
    const store = useStore()
    let chartData = ref({
      "labels": [],
      "datasets": [],
    });
    let chartOptions= ref({
      plugins: {
        legend: {
          labels: {
            color: '#000000'
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#000000'
          },
          grid: {
            color: '#000000'
          }
        },
        y: {
          beginAtZero: true,
          ticks: {
            color: '#000000'
          },
          grid: {
            color: '#000000'
          }
        }
      }
    })
    let dataDashboard = ref({
      httpresponse:[],
      success: false,
      selected:[],
      data:[],
      lazyParams:[],
      isupload: false,
      uploadprogress:0,
      uploadMessage:"",
      message:"",
      addToGid:"",
      loading: false
    })
    let dataChart = ref({
      httpresponse:[],
      success: false,
      selected:[],
      data:[],
      lazyParams:[],
      isupload: false,
      uploadprogress:0,
      uploadMessage:"",
      message:"",
      addToGid:"",
      loading: false
    })
    let dataLastTrx = ref({
      httpresponse:[],
      success: false,
      selected:[],
      data:[],
      lazyParams:[],
      isupload: false,
      uploadprogress:0,
      uploadMessage:"",
      message:"",
      addToGid:"",
      loading: false
    })
    const value = ref([
      { label: 'Contacts', color: '#34d399', value: 16, icon: 'bx bxs-contact' },
      { label: 'Groups', color: '#fbbf24', value: 8, icon: 'bx bx-group' },
      { label: 'Template', color: '#60a5fa', value: 240, icon: 'pi pi-image' },
      { label: 'WA Messages', color: '#c084fc', value: 10, icon: 'bx bxl-whatsapp' },
      { label: 'SMS Messages', color: '#c084fc', value: 10, icon: 'bx bx-message-detail' }
    ]);

    function loadDashboard(){
      dataDashboard.value.loading = true;
      store.dispatch('setLoading',true);
      let data = {
        type:'wa'
      }
      let path = '/dashboard/getbalance';
      DashboardServices.postData(data,path).then(
          (response)=>{
            dataDashboard.value.httpresponse = response.data
            if(response.data.status===200){
              dataDashboard.value.data = response.data.data
              dataDashboard.value.success = true;
            }else {
              dataDashboard.value.success = false;
            }
            store.dispatch('setLoading',false);
          }
      );
      dataDashboard.value.loading = false;
    }

    function getchart(){
      dataChart.value.loading = true;
      store.dispatch('setLoading',true);
      let data = {
        type:'wa'
      }
      let path = '/dashboard/getChartData';
      DashboardServices.postData(data,path).then(
          (response)=>{
            dataChart.value.httpresponse = response.data
            if(response.data.status===200){
              dataChart.value.data = response.data.data
              chartData.value = response.data.data.chart1
              dataChart.value.success = true;
            }else {
              dataChart.value.success = false;
            }
            store.dispatch('setLoading',false);
          }
      );
      dataChart.value.loading = false;
    }

    function getlasttrx(){
      dataLastTrx.value.loading = true;
      store.dispatch('setLoading',true);
      let data = {
        type:'wa'
      }
      let path = '/dashboard/last10trx';
      DashboardServices.postData(data,path).then(
          (response)=>{
            dataLastTrx.value.httpresponse = response.data
            if(response.data.status===200){
              dataLastTrx.value.data = response.data.data
              dataLastTrx.value.message = response.data.message
              dataLastTrx.value.success = true;
            }else {
              dataLastTrx.value.success = false;
            }
            store.dispatch('setLoading',false);
          }
      );
      dataLastTrx.value.loading = false;
    }

    onMounted(() => {
      loadDashboard()
      getchart()
      getlasttrx()
    })

    return{
      loading: computed(()=>store.getters.getLoadingStatus),
      dataDashboard,
      dataLastTrx,
      dataChart,
      chartOptions,
      chartData,
      value
    }
  }
}
</script>

<style scoped>

</style>