<template>
  <div class="container-xxl">
    <div class="authentication-wrapper authentication-basic container-p-y">
      <div class="authentication-inner">
        <!-- Register -->
        <div class="card">
          <div class="card-body">
            <!-- Logo -->
            <LogoBrand :center=true></LogoBrand>
            <!-- /Logo -->
            <h4 class="mb-2">Welcome to Broadcast Now! 👋</h4>
            <p class="mb-4">Please sign-in to your account and start the adventure</p>
            <form id="formAuthentication" class="mb-3" action="">
              <div class="mb-3">
                <label for="email" class="form-label">Email</label>
                <input
                    type="text"
                    class="form-control"
                    id="email"
                    v-model="dt_login.form.email"
                    placeholder="Enter your email"
                    autofocus />
              </div>
              <div class="mb-3 form-password-toggle">
                <div class="input-group input-group-merge">
                  <input
                      :type="passwrodType"
                      id="password"
                      class="form-control"
                      v-model="dt_login.form.password"
                      placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                      aria-describedby="password" />
                  <span class="input-group-text cursor-pointer" @click="changeVisiblity"><i :class="icon"></i></span>
                </div>
              </div>
              <div class="mb-3">
                <Message class="mb-1" v-if="dt_login.message.length>0">{{dt_login.message}}</Message>
                <button v-if="!loading" @click="login" class="btn btn-primary d-grid w-100" type="submit">Sign in</button>
                <ProgressSpinner v-else style="width: 50px; height: 50px" strokeWidth="8" fill="transparent"
                                 animationDuration=".5s" aria-label="Custom ProgressSpinner" />
              </div>
            </form>
          </div>
        </div>
        <!-- /Register -->
      </div>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, ref} from "vue";
import LogoBrand from "@/components/LogoBrand";
import DashboardServices from "@/services/dashboard.services";
import ProgressSpinner from 'primevue/progressspinner';
import Message from 'primevue/message';
import {useStore} from "vuex";
import router from "@/router/router";
export default {
  name: "LoginPages",
  components: {LogoBrand,ProgressSpinner,Message},
  setup(){
    const store = useStore()
    let passwrodType = ref("password")
    let icon = ref('bx bx-hide')
    let dt_login = ref({
      httpresponse: 0,
      response:[],
      success: false,
      key:'c_id',
      loading: false,
      list:[],
      form:{
        email:"",
        password:"",
      },
      message:"",
      rows:0,
      totalrow:0,
      lazy:true,
      lazyParams:[],
      pageoptions:[5, 10, 20, 50, 100],
      filters:{
      },
      filterDisplay:"row",
      globalFiltersField:[],
    })

    function changeVisiblity(){
      if(passwrodType.value ==='password'){
        passwrodType.value = 'text'
        icon.value = 'bx bx-show'
      }else{
        passwrodType.value = 'password'
        icon.value = 'bx bx-hide'
      }
    }

    function login(action){
      dt_login.value.lazy = true
      store.dispatch('setLoading',true)
      dt_login.value.loading = true
      let data = {
        action:action,
        data: dt_login.value.form,
        params: dt_login.value.lazyParams
      };
      let path = '/api/login';
      DashboardServices.postLogin(data,path).then(
          (response)=>{
            dt_login.value.httpresponse = response.data
            if(response.data.status===200){
              dt_login.value.list = response.data.data
              dt_login.value.message = response.data.message
              dt_login.value.success = true
              localStorage.setItem('broadcastuser', JSON.stringify(response))
              store.dispatch('setUserToken',JSON.stringify(response))
              router.push('/dashboard');
            }else {
              dt_login.value.message = response.data.error
              dt_login.value.success = false
            }
            dt_login.value.loading = false
            store.dispatch('setLoading',false)
          }
      ).catch((error)=>{
        dt_login.value.message = error.message
        dt_login.value.success = false
        dt_login.value.loading = true
        store.dispatch('setLoading',false)
      });
    }

    onMounted(() => {
      localStorage.removeItem('broadcastuser');
      store.dispatch('setUserToken','')
    })

    return{
      loading: computed(()=>store.getters.getLoadingStatus),
      userToken:computed(()=>store.getters.getUserToken),
      dt_login,
      passwrodType,
      changeVisiblity,
      login,
      icon
    }
  }
}
</script>

<style scoped>
@import "@/assets/vendor/css/pages/page-auth.css";
</style>