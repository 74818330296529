<template>
  <div class="container-xxl flex-grow-1 container-p-y text-start">
    <h4 class="py-3 mb-4">
      <span class="text-muted fw-light">Change</span> Password
    </h4>
    <div class="row">
      <div class="col-md-12">
        <AccordionPanel class="mb-2">
          <template #title>
            <b>Change password</b>
          </template>
          <template #content>
            <div class="row">
              <div class="col-md-6">
                <ProgressBar v-if="loading" mode="indeterminate" style="height: 2px"></ProgressBar>
                <div class="card mb-4">
                  <div class="card-body">
                    <form>
                      <div class="mb-3">
                        <label class="form-label" for="basic-default-current">Current Password</label>
                        <input type="password" v-model="dt_account.form.current" class="form-control" id="basic-default-current" placeholder="Current password">
                      </div>
                      <div class="mb-3">
                        <label class="form-label" for="basic-default-newpassword">New Password</label>
                        <input type="password" v-model="dt_account.form.newpassword" class="form-control" id="basic-default-newpassword" placeholder="New Password">
                      </div>
                      <div class="mb-3">
                        <label class="form-label" for="basic-default-repassword">Retype New Password</label>
                        <input type="password" v-model="dt_account.form.repassword" class="form-control" id="basic-default-repassword" placeholder="Retype new password">
                      </div>
                      <div class="mb-3">
                        <Message v-show="dt_account.message.length>0">{{dt_account.message}}</Message>
                        <Button v-if="!loading" label="Save" :disabled="loading" @click="crudAccount('save')" icon="pi pi-check" iconPos="right" class="m-2"/>
                        <ProgressSpinner v-else style="width: 50px; height: 50px" strokeWidth="8" fill="transparent" animationDuration=".5s" aria-label="Custom ProgressSpinner" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </AccordionPanel>
      </div>
    </div>
  </div>
</template>

<script>
import AccordionPanel from "@/components/accordion/AccordionPanel";
import ProgressBar from 'primevue/progressbar';
import Message from 'primevue/message'
import Button from 'primevue/button'
import ProgressSpinner from 'primevue/progressspinner';
import { FilterMatchMode } from '@primevue/core/api'
import {computed, ref} from "vue";
import {useStore} from "vuex";
import DashboardServices from "@/services/dashboard.services";
export default {
  name: "ChangePassword",
  components: {AccordionPanel,ProgressBar,Message,Button,ProgressSpinner},
  setup(){
    const store = useStore()
    let dt_account = ref({
      httpresponse: 0,
      response:[],
      success: false,
      key:'group_id',
      loading: false,
      form:{
        current:"",
        newpassword:"",
        repassword:""
      },
      message:"",
      rows:0,
      totalrow:0,
      lazy:true,
      lazyParams:[],
      pageoptions:[5, 10, 20, 50, 100],
      filters:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'group_name': {value: '', matchMode: FilterMatchMode.CONTAINS},
      },
      filterDisplay:"row",
      globalFiltersField:['c_fname','c_lname','c_phone','c_email','c_address'],
    })

    function crudAccount(action){
      dt_account.value.lazy = true
      store.dispatch('setLoading',true)
      dt_account.value.loading = true
      let data = {
        action:action,
        data: dt_account.value.form,
        params: dt_account.value.lazyParams
      };
      let path = '/dashboard/resetpassword';
      DashboardServices.postData(data,path).then(
          (response)=>{
            dt_account.value.httpresponse = response.data
            if(response.data.status===200){
              dt_account.value.list = response.data.data
              dt_account.value.message = response.data.message
              dt_account.value.success = true
              dt_account.value.form.current = "";
              dt_account.value.form.newpassword = "";
              dt_account.value.form.repassword = "";
            }else {
              dt_account.value.success = false
            }
            dt_account.value.loading = false
            store.dispatch('setLoading',false)
          }
      );
    }

    return{
      loading: computed(()=>store.getters.getLoadingStatus),
      dt_account,
      crudAccount
    }
  }
}
</script>

<style scoped>

</style>