<template>
  <div :class=cssClass>
    <router-link to="/" class="app-brand-link">
              <span class="app-brand-logo demo">
                <img class="img-fluid" :src="logoBrand" width="100" height="50"/>
              </span>
      <span class="app-brand-text demo menu-text fw-bold ms-2"></span>
    </router-link>

    <a @click="changeview" style="cursor: pointer" class="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
      <i class="bx bx-chevron-left bx-sm align-middle"></i>
    </a>
  </div>
</template>

<script>
import {computed} from "vue";
import { useStore } from 'vuex'
export default {
  name: "LogoBrand",
  props: {
    center: Boolean
  },
  setup(props){
    const store = useStore()
    const logoBrand = new URL('@/assets/logo1.jpg',import.meta.url)
    const imageUrl = new URL('@/assets/logo1.jpg', import.meta.url)
    const cssClass = computed(() => {
      return props.center===true ? 'app-brand justify-content-center' : 'app-brand demo'
    })

    function changeview(){
      store.dispatch('setMenuClose')
    }
    return {
      logoBrand,
      imageUrl,
      leftmenumode: computed(() => store.state.leftmenumode),
      cssClass,
      changeview
    }
  }
}
</script>

<style scoped>

</style>