<template>
  <nav
      class="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme d-none"
      id="layout-navbar">
    <div class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
      <a class="nav-item nav-link px-0 me-xl-4" style="cursor: pointer" @click="changeview">
        <i class="bx bx-menu bx-sm"></i>
      </a>
    </div>
    <div class="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
      <!-- Search -->
      <div class="navbar-nav align-items-center">
        <div class="nav-item d-flex align-items-center">
          <i class="bx bx-search fs-4 lh-0"></i>
          <input
              type="text"
              v-model="searchKeyword"
              @input="setKeyword"
              class="form-control border-0 shadow-none ps-1 ps-sm-2"
              placeholder="Search..."
              aria-label="Search..." />
        </div>
      </div>
      <!-- /Search -->
    </div>
  </nav>
</template>

<script>
import { useStore } from 'vuex'
import {ref,computed} from "vue";
import 'primeicons/primeicons.css'
export default {
  name: "TopNavBar",
  props:{

  },
  components:{},
  setup(){
    const store = useStore()
    let searchKeyword = ref("")
    function changeview(){
      store.dispatch('setMenuOpen')
    }
    function setKeyword(v){
      const tmp = v.target.value;
      store.dispatch('setKeyword',tmp)
    }
    return {
      leftmenumode: computed(() => store.state.leftmenumode),
      keyword: computed(() => store.state.searchkeyword),
      changeview,
      searchKeyword,
      setKeyword
    }
  }
}
</script>

<style scoped>

</style>