import {createRouter, createWebHistory} from "vue-router";
import RouterPages from "@/components/RouterPages";
import LoginPages from "@/components/pages/LoginPages";

const routes = [
    {
        path: '/dashboard',
        name: 'LandingPages',
        component: RouterPages,
        meta: {
            authRequired: 'true',
        },
    },
    {
        path: '/Unauthorized',
        name: 'Unauthorized',
        component: LoginPages,
        meta: {
            authRequired: 'false',
        },
    },
    {
        path: '/login',
        name: 'LoginPages',
        component: LoginPages,
        meta: {
            authRequired: 'false',
        },
    },
    {
        path: '/logout',
        name: 'LogoutPages',
        component: LoginPages,
        meta: {
            authRequired: 'false',
        },
    },
    {
        path: '/pages/:page',
        name: 'DynamicPages',
        component: RouterPages,
        meta: {
            authRequired: 'true',
        },
    },
]

const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes,
})

//createWebHashHistory
//createMemoryHistory

router.beforeEach((to, from, next) => {
    if (to.meta.authRequired === 'true') {
        let user = JSON.parse(localStorage.getItem('broadcastuser'))
        if (user.data.data.userRole === 0){
            return next()
        }else{
            router.push({
                name: 'Unauthorized'
            });
        }
    }else{
        if(to.path !== '/login'){
            return next('/login')
        }else{
            return next()
        }
    }
})

export default router