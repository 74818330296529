<template>
  <div class="container-xxl flex-grow-1 container-p-y text-start">
    <h4 class="py-3 mb-4">
      <span class="text-muted fw-light">Broadcast via</span> Whatsapp
    </h4>
    <div class="row">
      <div class="col-md-6">
        <div class="card mb-4">
          <h5 class="card-header">Compose Whatsapp Messages</h5>
          <div class="card-body">
            <div class="mb-3">
              <label class="form-label ">Contact Type</label>
              <div class="ml-5">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="dataTemplate.form.contactType" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="individual">
                  <label class="form-check-label ml-3" for="inlineRadio2">Individual Contact</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="dataTemplate.form.contactType" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="contact">
                  <label class="form-check-label ml-3" for="inlineRadio2">From Contact List</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="dataTemplate.form.contactType" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="group">
                  <label class="form-check-label ml-3" for="inlineRadio2">By Group</label>
                </div>
              </div>
            </div>
            <div class="mb-3" v-if="dataTemplate.form.contactType==='individual'">
              <label for="exampleFormControlInput1" class="form-label">Phone Number</label>
              <Transition name="slide-fade">
                <InputNumber v-model="dataTemplate.form.phonenumber" maxLength=20 class="form-control" id="exampleFormControlInput1" placeholder="6281xxx"></InputNumber>
              </Transition>
            </div>
            <div class="mb-3" v-if="dataTemplate.form.contactType==='group'">
              <label for="exampleFormControlInput1" class="form-label">Group Name</label>
              <Multiselect v-model="dataTemplate.form.gid"
                           deselect-label="Click to remove this value"
                           placeholder="Select group"
                           label="g_name"
                           track-by="g_name"
                           :options="dataTemplate.groupList"
                           openDirection="below"
                           :searchable="true"
                           :allow-empty="true">
                <template #selection>
                </template>
              </Multiselect>
            </div>
            <div class="mb-3" v-if="dataTemplate.form.contactType==='contact'">
              <label for="exampleFormControlInput1" class="form-label">Contact Name (Max 20)</label>
              <Multiselect v-model="dataTemplate.form.gid"
                           deselect-label="Click to remove this value"
                           tag-placeholder="Add this as new tag"
                           placeholder="Select contact"
                           label="fullnama"
                           track-by="fullnama"
                           :max="20"
                           :multiple="true"
                           :taggable="true"
                           openDirection="below"
                           :options="dataTemplate.contactList"
                           :searchable="true"
                           :allow-empty="true">
                <template #selection>
                </template>
              </Multiselect>
            </div>
            <div class="mb-3">
              <label for="FormControlInputCampaign" class="form-label">Campaign ID</label>
              <input type="text" maxlength="100" id="FormControlInputCampaign" v-model="dataTemplate.form.cid" class="form-control" placeholder="Campaign ID (optional)">
            </div>
            <div class="mb-3">
              <label for="exampleFormControlSelect2" class="form-label">Sender Name</label>
              <Multiselect v-model="dataTemplate.form.senderid"
                           id = "exampleFormControlSelect2"
                           deselect-label="Can't remove this value"
                           placeholder="Select sender"
                           label="s_name"
                           track-by="s_name"
                           :options="dataTemplate.senderList"
                           openDirection="below"
                           :searchable="true" :allow-empty="false">
                <template #selection>
                </template>
              </Multiselect>
            </div>
            <div class="mb-3">
              <label for="exampleFormControlSelect3" class="form-label">Template Name</label>
              <Multiselect v-model="dataTemplate.form.templateid"
                           id = "exampleFormControlSelect3"
                           deselect-label="Can't remove this value"
                           placeholder="Select template"
                           label="t_name"
                           track-by="t_name"
                           :options="dataTemplate.templateList"
                           openDirection="below"
                           @update:modelValue="showPreview"
                           :searchable="true" :allow-empty="false">
                <template #selection>
                </template>
              </Multiselect>
            </div>
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">Message</label>
              <div class="input-group">
                <InputTextGroup id="exampleFormControlTextarea1" v-for="(item,idx) in dataTemplate.form.templateValue" :key="idx" v-model="dataTemplate.form.templateValue[idx].value" :label="getLabel(item.field)" type="text" maxlength="100" class="mb-1"></InputTextGroup>
              </div>
            </div>
            <div class="mb-3">
              <label for="html5-datetime-local-input" class="col-md-2 col-form-label">Schedule</label>
              <div class="col-md-10">
                <input class="form-control" v-model="dataTemplate.form.schedule" type="datetime-local" placeholder="2021-06-18T12:30:00" id="html5-datetime-local-input">
              </div>
            </div>
            <div class="mb-3" v-if="showImage">
              <label for="exampleFormControlImage" class="form-label">Attachment Name</label>
              <Multiselect v-model="dataTemplate.form.fileAttachment"
                           id = "exampleFormControlImage"
                           deselect-label="Can't remove this value"
                           placeholder="Select Image"
                           label="image_tag"
                           track-by="image_tag"
                           :options="dataTemplate.attachmentList"
                           openDirection="below"
                           @update:modelValue="showImageAttachment"
                           :searchable="true" :allow-empty="false">
                <template #selection>
                </template>
              </Multiselect>
            </div>
            <div class="mb-3" v-if="shoImageUpload">
              <label for="html5-datetime-local-input" class="col-md-2 col-form-label">Attachment</label>
              <ProgressBar v-if="dataTemplate.isupload" :value="dataTemplate.uploadprogress"></ProgressBar>
              <FileUpload name="attachment" :url="fileuploadUrl"
                          @before-send="beforeUpload"
                          @upload="onUpload"
                          @select="onSelect"
                          @progress="onProgress"
                          :multiple="false"
                          :fileLimit="1"
                          accept="image/png,image/jpeg,image/jpg"
                          :maxFileSize="5000000">
                <template #empty>
                  <span>Drag and drop files to here to upload.</span>
                </template>
              </FileUpload>
            </div>
            <div class="mb-3">
              <Message v-show="dataTemplate.message.length>0">{{dataTemplate.message}}</Message>
              <Button v-if="!loading" label="Save" :disabled="loading" @click="crudTemplate('save')" icon="pi pi-check" iconPos="right" class="m-2"/>
              <ProgressSpinner v-else style="width: 50px; height: 50px" strokeWidth="8" fill="transparent" animationDuration=".5s" aria-label="Custom ProgressSpinner" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card mb-4">
          <h5 class="card-header">Preview</h5>
          <div class="card-body">
            <WaScreen :image="dataTemplate.form.image" :watext="showTemplate" :name="dataTemplate.form.senderid.s_name"></WaScreen>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex"
import Multiselect from 'vue-multiselect'
import InputNumber from "@/components/input/InputNumber";
import {computed, onMounted, ref} from "vue";
import WaScreen from "@/components/whatsappscreen/WaScreen";
import InputTextGroup from "@/components/input/InputTextGroup";
import DashboardServices from "@/services/dashboard.services";
import Button from 'primevue/button'
import Message from 'primevue/message'
import FileUpload from 'primevue/fileupload';
import ProgressSpinner from 'primevue/progressspinner';
import dashboardServices from "@/services/dashboard.services";
import ProgressBar from 'primevue/progressbar';
export default {
  name: "BroadcastWhatsapp",
  components: {InputTextGroup, WaScreen, Multiselect,InputNumber,Button,Message,FileUpload,ProgressSpinner,ProgressBar},
  setup(){
    const store = useStore()
    let message = ref("")
    let phonenumber = ref("")
    let maxmessage = ref(150)
    let prevTemplate = ref("")
    let fieldSize = ref(0)
    let showImage = ref(false)
    const file = ref(null)
    let imageurl = ref("")
    let fileuploadUrl = ref("")
    let shoImageUpload = ref(false)
    let dataTemplate = ref({
      httpresponse:[],
      success: false,
      selected:[],
      groupLoading: false,
      attachmentLoading: false,
      senderLoading: false,
      templateLoading: false,
      contactLoading: false,
      templateList:[],
      contactList:[],
      groupList:[],
      attachmentList:[],
      senderList:[],
      lazyParams:[],
      isupload: false,
      uploadprogress:0,
      uploadMessage:"",
      form: {
        messageType:'wa',
        contactType: "individual",
        phonenumber: "",
        gid:"",
        cid:"",
        senderid:"",
        schedule:"",
        templateid:"",
        templateValue:[],
        message:"",
        image:"",
        fileAttachment:""
      },
      message:"",
      addToGid:""
    })

    function getClass(condition){
      if(dataTemplate.value.form.contactType===condition){
        return "collapse show"
      }else{
        return "collapse"
      }
    }
    function setTel(v) {
      const val = v.target.value.replace(/[^0-9]/g, "");
      phonenumber.value = val;
      /*this.tel = v.target.value = v.target.value.replace(/[^0-9]/g, "");*/
    }

    const countmsg = computed(()=>{
      let x = Math.ceil(dataTemplate.value.form.message.length / 150)
      if(x>1){
        return x+' messages'
      }else{
        return x+' message'
      }
    })

    function beforeUpload(request){
      let token = dashboardServices.getUserToken();
      request.xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      return request;
    }

    const onProgress = (event) => {
      dataTemplate.value.isupload = true;
      dataTemplate.value.uploadprogress = event.progress
    }

    const onUpload = (event) => {
      let response = event.xhr.response
      const obj = JSON.parse(response)
      dataTemplate.value.uploadMessage = obj.message
      dataTemplate.value.message = obj.message
      if(obj.data.filecode){
        dataTemplate.value.form.fileAttachment = obj.data.filecode
        dataTemplate.value.form.image = DashboardServices.generateUrl('viewimage/'+obj.data.filecode)
      }
    }

    const onSelect = (event) => {
      dataTemplate.value.isupload = true;
      dataTemplate.value.form.fileAttachment = event.files[0].name;
    }

    function crudTemplate(action){
      store.dispatch('setLoading',true)
      let data = {
        action:action,
        data: dataTemplate.value.form,
        params: dataTemplate.value.lazyParams
      };
      let path = '/dashboard/getData/addtask';
      DashboardServices.postData(data,path).then(
          (response)=>{
            dataTemplate.value.httpresponse = response.data
            if(response.data.status===200){
              dataTemplate.value.message = response.data.message
              dataTemplate.value.success = true
            }else {
              dataTemplate.value.success = false
            }
            dataTemplate.value.loading = false
            store.dispatch('setLoading',false)
          }
      );
    }

    function loadGroupList(){
      dataTemplate.value.groupLoading = true
      store.dispatch('setLoading',true)
      let data = {}
      let path = '/dashboard/getData/groups';
      DashboardServices.postData(data,path).then(
          (response)=>{
            dataTemplate.value.httpresponse = response.data
            if(response.data.status===200){
              dataTemplate.value.groupList = response.data.data
              dataTemplate.value.success = true
            }else {
              dataTemplate.value.success = false
            }
            store.dispatch('setLoading',false)
          }
      )
      dataTemplate.value.groupLoading = false
    }

    function loadImageList(){
      dataTemplate.value.attachmentLoading = true
      store.dispatch('setLoading',true)
      let data = {}
      let path = '/dashboard/getData/dataImageList';
      DashboardServices.postData(data,path).then(
          (response)=>{
            dataTemplate.value.httpresponse = response.data
            if(response.data.status===200){
              dataTemplate.value.attachmentList = response.data.data
              dataTemplate.value.success = true
            }else {
              dataTemplate.value.success = false
            }
            store.dispatch('setLoading',false)
          }
      )
      dataTemplate.value.attachmentLoading = false
    }

    function loadSenderList(){
      dataTemplate.value.senderLoading = true;
      store.dispatch('setLoading',true);
      let data = {
        type:'wa'
      }
      let path = '/dashboard/getData/sender';
      DashboardServices.postData(data,path).then(
          (response)=>{
            dataTemplate.value.httpresponse = response.data
            if(response.data.status===200){
              dataTemplate.value.senderList = response.data.data
              dataTemplate.value.success = true;
            }else {
              dataTemplate.value.success = false;
            }
            store.dispatch('setLoading',false);
          }
      );
      dataTemplate.value.senderLoading = false;
    }

    function loadTemplateList(){
      dataTemplate.value.templateLoading = true;
      store.dispatch('setLoading',true);
      let data = {
        type:'wa'
      }
      let path = '/dashboard/getData/template';
      DashboardServices.postData(data,path).then(
          (response)=>{
            dataTemplate.value.httpresponse = response.data
            if(response.data.status===200){
              dataTemplate.value.templateList = response.data.data
              dataTemplate.value.success = true;
            }else {
              dataTemplate.value.success = false;
            }
            store.dispatch('setLoading',false);
          }
      );
      dataTemplate.value.templateLoading = false;
    }

    function loadContactList(){
      dataTemplate.value.contactLoading = true;
      store.dispatch('setLoading',true);
      let data = {
        type:'wa'
      }
      let path = '/dashboard/getData/dataContacts';
      DashboardServices.postData(data,path).then(
          (response)=>{
            dataTemplate.value.httpresponse = response.data
            if(response.data.status===200){
              dataTemplate.value.contactList = response.data.data
              dataTemplate.value.success = true;
            }else {
              dataTemplate.value.success = false;
            }
            store.dispatch('setLoading',false);
          }
      );
      dataTemplate.value.contactLoading = false;
    }

    function ubahPreview(){
      prevTemplate.value = dataTemplate.value.form.templateid.t_code;
      for (let i = 0; i < dataTemplate.value.form.templateValue.length; i++) {
        let code = dataTemplate.value.form.templateValue[i].field
        code++;
        let value = dataTemplate.value.form.templateValue[i].value;
        let replaceWith = '<b>'+dataTemplate.value.form.templateValue[i].value+'</b>'
        if(value.length>0){
          prevTemplate.value = prevTemplate.value.replace('{{'+code+'}}', replaceWith);
        }
      }
      return prevTemplate.value
    }

    function showImageAttachment(){
      dataTemplate.value.form.image = DashboardServices.generateUrl('/viewimage/'+dataTemplate.value.form.fileAttachment.file_code)
    }

    function showPreview(){
      dataTemplate.value.form.templateValue = [];
      const regex = /\{{\d\}}/gm;
      showImage.value = false;
      dataTemplate.value.form.image = "";
      if(dataTemplate.value.form.templateid.with_img===1){
        showImage.value = true;
      }
      prevTemplate.value = dataTemplate.value.form.templateid.t_code;
      fieldSize.value = (prevTemplate.value.match(new RegExp(regex, "gm")) || []).length
      for (let i = 0; i < fieldSize.value; i++) {
        dataTemplate.value.form.templateValue.push({
          field:i,
          value:""
        });
      }
    }

    const handleFileUpload = async() => {
      imageurl.value = URL.createObjectURL(file.value.files[0]);
    }

    function clearImage(){
      file.value.target = null
      imageurl.value = ""
    }

    function makeLabel(idx) {
      ++idx;
      return idx.toString()
    }

    onMounted(() => {
      fileuploadUrl.value = DashboardServices.generateUrl('dashboard/uploadfile')
      loadGroupList()
      loadTemplateList()
      loadSenderList()
      loadImageList()
      loadContactList()
    })

    return {
      loading: computed(()=>store.getters.getLoadingStatus),
      getClass,
      message,
      maxmessage,
      countmsg,
      msgcounter:computed(() => {return dataTemplate.value.form.message.length}),
      getLabel:makeLabel,
      setTel,
      dataTemplate,
      loadContactList,
      showTemplate:computed(()=>ubahPreview()),
      showPreview,
      showImageAttachment,
      fieldSize,
      showImage,
      crudTemplate,
      handleFileUpload,
      file,
      imageurl,
      clearImage,
      fileuploadUrl,
      beforeUpload,
      onProgress,
      onUpload,
      onSelect,
      shoImageUpload
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
:deep(.multiselect__input) {
  border: none;
  width:150px;
}

</style>