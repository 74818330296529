<template>
  <div v-if="loggedIn">
    <MainDashboardPage></MainDashboardPage>
  </div>
  <div v-else>
    <LoginPages></LoginPages>
  </div>
</template>

<script>
import LoginPages from "@/components/pages/LoginPages";
import MainDashboardPage from "@/components/MainDashboardPage";
export default {
  name: "RouterPages",
  components: {MainDashboardPage, LoginPages},
  setup(){
    const loggedIn = true;
    return {loggedIn}
  }
}
</script>

<style scoped>

</style>