<template>
  <div class="w-100 h-100 overlay d-none"></div>
  <!-- Navbar -->
  <div class="row d-flex flex-row align-items-center p-2 m-0" id="navbar">
    <div class="row">
      <div class="d-flex">
        <div class="flex-shrink-0">
          <img src="@/assets/whatsapp/profile.png" alt="google" class="me-3 img-fluid rounded-circle" style="height:50px;">
        </div>
        <div class="flex-grow-1 row">
          <div class="col-9 mb-sm-0 mb-2">
            <h6 class="mb-0 text-white fw-bold">{{ name }} <i class='bx bxs-badge-check'></i></h6>
            <small class="text-white">Business Account</small>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Messages -->
  <div class="d-flex flex-column" id="messages">
    <div class="mx-auto my-2 bg-primary text-white small py-1 px-2 rounded">
      {{curdate}}
    </div>
    <div class="align-self-start p-1 my-1 mx-3 rounded bg-white shadow-sm message-item">
      <div class="options">
        <a href="#"><i class="fas fa-angle-down text-muted px-2"></i></a>
      </div>
      <div class="row">
        <img v-if="image.length>0" :src="image" class="img-thumbnail rounded float-start">
      </div>
      <div class="d-flex flex-row">
        <div class="body m-1 mr-2" v-html="watext"></div>
        <div class="row">
          <div class="time ml-auto small text-right flex-shrink-0 align-self-end text-muted" style="width:75px;">
            {{curtime}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WaScreen",
  props:{
    name: String,
    strdate:String,
    watext: String,
    image: String
  },
  setup(){
    function getDate(){
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      today = mm + '/' + dd + '/' + yyyy;
      return today
    }
    function getTime(){
      return new Date().toLocaleTimeString('en-US', { hour12: false,
        hour: "numeric",
        minute: "numeric"});
    }
    return {
      curdate: getDate(),
      curtime: getTime()
    }
  }
}
</script>

<style scoped>

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}

#navbar {
  background: #009688;
}

.dropdown-toggle::after {
  display: none;
}

.chat-list-item {
  background: white;
  cursor: pointer;
}

.chat-list-item:hover {
  background: hsl(0, 0%, 95%);
}

.chat-list-item:active {
  background: hsl(0, 0%, 85%);
}

.chat-list-item.active {
  background: hsl(0, 0%, 90%);
}

.chat-list-item .chat-details {
  width: 60%;
}

.chat-list-item.unread .name,
.chat-list-item.unread .last-message {
  font-weight: bold;
}

.chat-list-item .last-message,
#message-area #navbar #details {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#message-area {
  border-left: 1px solid white;
}

#message-area .overlay {
  background: hsl(0, 0%, 80%);
}

#input-area {
  background: hsl(0, 0%, 95%);
}

#input-area #input {
  outline: none;
}

.message-item {
  position:relative;
  max-width: 75%;
  word-break: break-word;
}
.message-item.self {
  background: #dcf8c6!important;
}
.message-item .number {
  color: #1f7aec !important;
}
.message-item .options {
  position: absolute;
  top: 0;
  right: -3px;
  opacity: 0;
  transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
}
.message-item:hover .options {
  opacity: 1;
  right: 0;
}

#messages {
  flex: 1!important;
  background: hsl(0, 0%, 80%);
  overflow: auto;
}

#profile-settings {
  position: absolute;
  top: 0;
  left: -110%;
  background: hsl(0, 0%, 95%);
  transition: all 0.2s ease-in;
  -moz-transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
}

#profile-pic {
  cursor: pointer;
  position: relative;
  width: 200px;
}

.profile-input {
  border-bottom: 2px solid transparent  !important;
  outline: none;
}

.profile-input:focus {
  border-bottom-color: hsl(0, 0%, 50%) !important;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}
</style>