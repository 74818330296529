import { createStore } from 'vuex'
// Create a new store instance.

const state = {
    count: 0,
    loading: false,
    leftmenumode: false,
    logobrand: '@/assets/logo.jpeg',
    searchkeyword: "",
    usertoken:""
}

const mutations = {
    increment (state){
        state.count++
    },
    decrement (state){
        state.count--
    },
    setmenuclose(state){
        state.leftmenumode = false;
    },
    setmenuopen(state){
        state.leftmenumode = true;
    },
    setsearchkeyword(state,data){
        state.searchkeyword = data
    },
    setloading(state,status){
        state.loading = status;
    },
    setusertoken(state,token){
        state.usertoken = token;
    }
}

const actions = {
    setMenuClose: ({ commit })=> commit('setmenuclose'),
    setMenuOpen: ({ commit })=> commit('setmenuopen'),
    setKeyword: ({ commit }, data)=> commit('setsearchkeyword',data),
    setLoading: ({ commit }, data)=> commit('setloading',data),
    setUserToken: ({ commit }, data)=> commit('setusertoken',data),
}

const getters = {
    evenOrOdd: state => state.count % 2 === 0 ? 'even' : 'odd',
    getBrandLogo: state=> state.logobrand,
    getSearchKeyword: state=> state.searchkeyword,
    getLoadingStatus: state=> state.loading,
    getUserToken: state=> state.usertoken
}

export default createStore({
    state,
    getters,
    actions,
    mutations
})