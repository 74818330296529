<template>
  <div class="container-xxl flex-grow-1 container-p-y text-start">
    <h4 class="py-3 mb-4">
      <span class="text-muted fw-light">Setting /</span> Broadcast Setting
    </h4>
    <div class="row">
      <div class="col-md-6 col-12 mb-md-0 mb-4">
        <div class="card">
          <h5 class="card-header">Setting</h5>
          <div class="card-body" v-if="!loading">
            <div class="d-flex mb-3" v-for="(setting,idx) in dt_setting.list" :key="idx">
              <div class="flex-shrink-0">
                <i :class=setting.icon_sts></i>
              </div>
              <div class="flex-grow-1 row">
                <div class="col-9 mb-sm-0 mb-2">
                  <h6 class="mb-0 text-uppercase">{{setting.setting_text}}</h6>
                  <small class="text-muted">{{setting.setting_info}}</small>
                </div>
                <div class="col-3 text-end">
                  <div class="form-check form-switch">
                    <input class="form-check-input float-end" v-model="setting.setting_sts" @change="crudSetting('save',setting)" type="checkbox" role="switch">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ProgressSpinner v-else style="width: 50px; height: 50px" strokeWidth="8" fill="transparent" animationDuration=".5s" aria-label="Custom ProgressSpinner" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, ref} from "vue";
import DashboardServices from "@/services/dashboard.services";
import ProgressSpinner from 'primevue/progressspinner';
import {useStore} from "vuex";
export default {
  name: "SettingPage",
  components:{ProgressSpinner},
  setup(){
    const store = useStore()
    let status = ref({
      waStatus: false,
      smsStatus: false
    })

    let dt_setting = ref({
      lazy : false,
      loading: false,
      list:[],
      message:"",
      success: false
    })

    function crudSetting(action,setting){
      dt_setting.value.lazy = true
      store.dispatch('setLoading',true)
      dt_setting.value.loading = true
      let data = {
        action:action,
        data: setting,
        params: {}
      };
      let path = '/dashboard/getData/setting';
      DashboardServices.postData(data,path).then(
          (response)=>{
            dt_setting.value.httpresponse = response.data
            if(response.data.status===200){
              dt_setting.value.list = response.data.data
              dt_setting.value.message = response.data.message
              dt_setting.value.success = true
            }else {
              dt_setting.value.success = false
            }
            dt_setting.value.loading = false
            store.dispatch('setLoading',false)
          }
      );
    }

    onMounted(() => {
      crudSetting();
    })
    return{
      loading: computed(()=>store.getters.getLoadingStatus),
      status,
      crudSetting,
      dt_setting
    }
  }
}
</script>

<style scoped>

</style>