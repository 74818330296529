<template>
  <div class="container-xxl flex-grow-1 container-p-y text-start">
    <h4 class="py-3 mb-4">
      <span class="text-muted fw-light">Create /</span> WA Broadcast Template
    </h4>
    <div class="row">
      <div class="col-md-6">
        <ProgressBar v-if="loading" mode="indeterminate" style="height: 2px"></ProgressBar>
        <div class="card mb-4">
          <div class="card-header d-flex justify-content-between align-items-center">
            <h5 class="mb-0">Add Template</h5>
            <small class="text-muted float-end">Form template</small>
          </div>
          <div class="card-body">
            <form>
              <div class="mb-3">
                <label class="form-label" for="basic-default-firstname">Name</label>
                <input type="text" v-model="dt_template.form.name" class="form-control" id="basic-default-firstname" placeholder="Template Name">
              </div>
              <div class="mb-3">
                <label class="form-label" for="basic-default-code">Code</label>
                <textarea cols="10" rows="5" v-model="dt_template.form.code" class="form-control" id="basic-default-code" placeholder="Template Code"></textarea>
                <div class="form-text">Make sure this template is exactly like in Facebook Whatsapp Manager</div>
              </div>
              <div class="d-flex mb-3">
                <div class="flex-grow-1 row">
                  <div class="col-9 mb-sm-0 mb-2">
                    <h6 class="mb-0">With Attachment?</h6>
                    <small class="text-muted">Is template contain image attachment</small>
                  </div>
                  <div class="col-3 text-end">
                    <div class="form-check form-switch">
                      <input v-model="dt_template.form.image" class="form-check-input float-end" type="checkbox" role="switch">
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <Message v-show="dt_template.message.length>0">{{dt_template.message}}</Message>
                <Button v-if="!loading" label="Save" :disabled="loading" @click="crudTemplate('save')" icon="pi pi-check" iconPos="right" class="m-2"/>
                <ProgressSpinner v-else style="width: 50px; height: 50px" strokeWidth="8" fill="transparent" animationDuration=".5s" aria-label="Custom ProgressSpinner" />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <div class="card-datatable table-responsive">
              <DataTable
                  ref="dt"
                  :dataKey="dt_template.key"
                  :filters="dt_template.filters"
                  :globalFilterFields="dt_template.globalFiltersField"
                  :filterDisplay="dt_template.filterDisplay"
                  :value="dt_template.list"
                  :rows="dt_template.rows"
                  :totalRecords="dt_template.totalrow"
                  :loading="dt_template.loading"
                  :lazy="dt_template.lazy"
                  :rowsPerPageOptions="dt_template.pageoptions"
                  @page="onPageTemplate"
                  loadingIcon="pi pi-spin pi-spinner"
                  tableStyle=""
                  size=""
                  class="overflow-auto w-auto"
                  stripedRows
                  paginator
              >
                <template #empty> No template found. </template>
                <template #loading> Loading template data. Please wait. </template>
                <Column field="no" header="No"></Column>
                <Column field="t_name" header="Name"></Column>
                <Column header="Type">
                  <template #body="{ data }">
                    <span class="badge rounded-pill bg-primary" v-if="data.t_type==='wa'">{{ data.t_type }}</span>
                    <span v-else class="badge rounded-pill bg-secondary">{{ data.t_type }}</span>
                  </template>
                </Column>
                <Column field="t_code" header="Template"></Column>
                <Column header="Image">
                  <template #body="{ data }">
                    <span class="badge badge-center rounded-pill bg-label-primary" v-if="data.with_img===1">Yes</span>
                    <span class="badge badge-center rounded-pill bg-label-danger" v-if="data.with_img===0">NO</span>
                  </template>
                </Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import ProgressBar from 'primevue/progressbar';
import {computed, onMounted, ref} from "vue";
import Message from 'primevue/message'
import Button from 'primevue/button'
import ProgressSpinner from 'primevue/progressspinner';
import { FilterMatchMode } from '@primevue/core/api'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import DashboardServices from "@/services/dashboard.services";
export default {
  name: "BroadcastTamplatePage",
  components:{ProgressBar,Message,Button,ProgressSpinner,DataTable,Column},
  setup(){
    const store = useStore()
    let dt_template = ref({
      httpresponse: 0,
      response:[],
      success: false,
      key:'group_id',
      loading: false,
      form:{
        name:"",
        code:"",
        image:false
      },
      message:"",
      rows:0,
      totalrow:0,
      lazy:true,
      lazyParams:[],
      pageoptions:[5, 10, 20, 50, 100],
      filters:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'group_name': {value: '', matchMode: FilterMatchMode.CONTAINS},
      },
      filterDisplay:"row",
      globalFiltersField:['c_fname','c_lname','c_phone','c_email','c_address'],
    })

    const onPageTemplate = (event) => {
      dt_template.value.lazyParams = event
      crudTemplate('read')
    }

    function setupDisplay(data,pagination){
      data.value.rows = pagination.rows
      data.value.totalrow = pagination.records
    }

    function crudTemplate(action){
      dt_template.value.lazy = true
      store.dispatch('setLoading',true)
      dt_template.value.loading = true
      let data = {
        action:action,
        data: dt_template.value.form,
        params: dt_template.value.lazyParams
      };
      let path = '/dashboard/getData/dataTemplate';
      DashboardServices.postData(data,path).then(
          (response)=>{
            dt_template.value.httpresponse = response.data
            if(response.data.status===200){
              dt_template.value.list = response.data.data
              setupDisplay(dt_template,response.data.pagination)
              dt_template.value.message = response.data.message
              dt_template.value.success = true
            }else {
              dt_template.value.success = false
            }
            dt_template.value.loading = false
            store.dispatch('setLoading',false)
          }
      );
    }

    onMounted(() => {
      crudTemplate('read')
    })

    return{
      loading: computed(()=>store.getters.getLoadingStatus),
      dt_template,
      crudTemplate,
      onPageTemplate
    }
  }
}
</script>

<style scoped>

</style>