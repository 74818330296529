<template>
  <div class="container-xxl flex-grow-1 container-p-y text-start">
    <h4 class="py-3 mb-4">
      <span class="text-muted fw-light">Contact /</span> All Customers
    </h4>
    <!-- customers List Table -->
    <div class="row">
      <Toast />
      <ConfirmPopup></ConfirmPopup>
      <div class="col-md-12">
        <ProgressBar v-if="loading" mode="indeterminate" style="height: 2px"></ProgressBar>
        <div class="nav-align-top mb-4">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item" role="presentation">
              <button type="button" :class=getTabClass(0) @click="changeTab(0)" role="tab" data-bs-toggle="tab" data-bs-target="#navs-top-home" aria-controls="navs-top-home" aria-selected="false" tabindex="-1">
                Data Table
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button type="button" :class=getTabClass(1) @click="changeTab(1)" role="tab" data-bs-toggle="tab" data-bs-target="#navs-top-profile" aria-controls="navs-top-profile" aria-selected="false" tabindex="-1">
                Add Contact
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button type="button" :class=getTabClass(2) @click="changeTab(2)" role="tab" data-bs-toggle="tab" data-bs-target="#navs-top-messages" aria-controls="navs-top-messages" aria-selected="true">
                Add Groups
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button type="button" :class=getTabClass(3) @click="changeTab(3)" role="tab" data-bs-toggle="tab" data-bs-target="#navs-top-messages" aria-controls="navs-top-messages" aria-selected="true">
                Import Contact
              </button>
            </li>
          </ul>
          <div class="tab-content">
            <div :class="getTabContentClass(0)" id="navs-top-home" role="tabpanel">
              <div class="card-datatable table-responsive">
                <DataTable
                    ref="dtcontacts"
                    :dataKey="dt_contacts.key"
                    :filters="dt_contacts.filters"
                    :globalFilterFields="dt_contacts.globalFiltersField"
                    :value="dt_contacts.list"
                    :rows="dt_contacts.rows"
                    :totalRecords="dt_contacts.totalrow"
                    :loading="dt_contacts.loading"
                    :lazy="dt_contacts.lazy"
                    :rowsPerPageOptions="dt_contacts.pageoptions"
                    @page="onPageContact"
                    loadingIcon="pi pi-spin pi-spinner"
                    tableStyle="min-width: 50rem"
                    size=""
                    class="overflow-auto w-auto"
                    stripedRows
                    paginator
                    selectionMode="multiple"
                    :metaKeySelection=true
                    v-model:selection="contact.selected"
                >
                  <template #header>
                    <div class="row">
                      <div class="col-md-3">
                        <IconField>
                          <InputIcon>
                            <i class="pi pi-search" />
                          </InputIcon>
                          <InputText v-model="dt_contacts.filters['global'].value" placeholder="Keyword Search" @keyup="onfilterContact" />
                        </IconField>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6" v-if="!contact.groupLoading">
                            <Multiselect v-if="!loading" v-model="contact.addToGid"
                                         deselect-label="Can't remove this value"
                                         placeholder="Select Groups"
                                         label="g_name"
                                         track-by="g_name"
                                         class=""
                                         openDirection="below"
                                         :options="contact.groupList"
                                         :searchable="true" :allow-empty="false"
                            >
                              <template #selection>
                              </template>
                            </Multiselect>
                          </div>
                          <div class="col-md-3">
                            <Button v-if="!contact.groupLoading" label="Add" :disabled="loading" @click="addToGroup('save')" icon="pi pi-check" iconPos="right" class=""/>
                            <ProgressSpinner v-else style="width: 50px; height: 50px" strokeWidth="8" fill="transparent" animationDuration=".5s" aria-label="Custom ProgressSpinner" />
                          </div>
                          <div class="col-md-3" v-if="showDelete">
                            <Button v-if="!contact.deleteLoading" label="Delete" :disabled="loading" @click="deleteContact()" icon="pi pi-check" iconPos="right" class="" severity="danger"/>
                            <ProgressSpinner v-else style="width: 50px; height: 50px" strokeWidth="8" fill="transparent"
                                             animationDuration=".5s" aria-label="Custom ProgressSpinner" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-12">
                        <Message v-show="contact.message.length>0">{{contact.message}}</Message>
                      </div>
                    </div>
                  </template>
                  <template #empty> No contact found. </template>
                  <template #loading> Loading contact data. Please wait. </template>
                  <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                  <Column field="no" header="No" headerStyle="width: 3rem"></Column>
                  <Column header="Name" headerStyle="width: 3rem">
                    <template #body="{ data }">
                      {{ data.c_fname }} {{ data.c_lfname }}
                    </template>
                  </Column>
                  <Column field="c_phone" header="Phone" headerStyle="width: 3rem"></Column>
                  <Column field="c_email" header="Email" headerStyle="width: 3rem"></Column>
                  <Column field="c_address" header="Address" headerStyle="width: 3rem"></Column>
                  <Column header="Groups" headerStyle="width: 3rem">
                    <template #body="{ data }">
                      <Chip v-for="(item,idx) in labelit(data.groupname)" :label="item" :key="idx" removable @remove="groupRemove(data,item)" class="bg-warning mb-1" />
                    </template>
                  </Column>
                  <Column header="Edit" headerStyle="width: 3rem">
                    <template #body="{ data }">
                      <i v-if="data===dt_contacts.item" class="bx bx-check"></i>
                      <i v-else @click="doEditContact('delete',data)" class="bx bx-edit" style="cursor: pointer"></i>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
            <div :class="getTabContentClass(1)" id="navs-top-profile1" role="tabpanel">
              <div class="col-xl">
                <div class="card mb-4">
                  <div class="card-header d-flex justify-content-between align-items-center">
                    <h5 class="mb-0">Add Contact</h5>
                    <small class="text-muted float-end">Form contact</small>
                  </div>
                  <div class="card-body">
                    <form>
                      <div class="mb-3">
                        <label class="form-label" for="basic-default-firstname">First Name</label>
                        <input type="text" v-model="dt_contacts.form.firstname" class="form-control" id="basic-default-firstname" placeholder="John">
                      </div>
                      <div class="mb-3">
                        <label class="form-label" for="basic-default-lastname">Last Name</label>
                        <input type="text" v-model="dt_contacts.form.lastname" class="form-control" id="basic-default-lastname" placeholder="Doe">
                      </div>
                      <div class="mb-3">
                        <label class="form-label" for="basic-default-company">Company</label>
                        <input type="text" v-model="dt_contacts.form.company" class="form-control" id="basic-default-company" placeholder="PT. Company">
                      </div>
                      <div class="mb-3">
                        <label class="form-label" for="basic-default-phone">Phone No</label>
                        <input type="text" v-model="dt_contacts.form.phoneno" class="form-control" id="basic-default-phone" placeholder="6281xxxxx">
                      </div>
                      <div class="mb-3">
                        <label class="form-label" for="basic-default-email">Email</label>
                        <div class="input-group input-group-merge">
                          <input type="text" v-model="dt_contacts.form.email" id="basic-default-email" class="form-control" placeholder="john.doe" aria-label="john.doe" aria-describedby="basic-default-email2">
                          <span class="input-group-text" id="basic-default-email2">@example.com</span>
                        </div>
                        <div class="form-text">You can use letters, numbers &amp; periods</div>
                      </div>
                      <div class="mb-3">
                        <label class="form-label">Province</label>
                        <Multiselect v-if="!loading" v-model="dt_contacts.form.province"
                                     deselect-label="Can't remove this value"
                                     placeholder="Select province"
                                     label="value"
                                     track-by="value"
                                     :options="indonesia.provinceList"
                                     :searchable="true" :allow-empty="false"
                                     @update:modelValue="showCity"
                        >
                          <template #selection>
                          </template>
                        </Multiselect>
                        <ProgressBar v-else mode="indeterminate" style="height: 3px"></ProgressBar>
                      </div>
                      <div class="mb-3" v-if="indonesia.provinceList.length>0">
                        <label class="form-label">City/Regency</label>
                        <Multiselect v-if="!indonesia.cityLoading" v-model="dt_contacts.form.city"
                                     deselect-label="Can't remove this value"
                                     placeholder="Select City"
                                     label="cityname"
                                     track-by="cityname"
                                     :options="indonesia.cityList"
                                     @update:modelValue="showSubDistrict"
                                     :searchable="true" :allow-empty="false"
                        >
                          <template #selection>
                          </template>
                        </Multiselect>
                        <ProgressBar v-else mode="indeterminate" style="height: 3px"></ProgressBar>
                      </div>
                      <div class="mb-3">
                        <label class="form-label">Subdistrict</label>
                        <Multiselect v-if="!indonesia.subDistrictLoading" v-model="dt_contacts.form.subdistrict"
                                     deselect-label="Can't remove this value"
                                     placeholder="Select Subdistrict"
                                     label="value"
                                     track-by="value"
                                     :options="indonesia.subList"
                                     :searchable="true" :allow-empty="false"
                        >
                          <template #selection>
                          </template>
                        </Multiselect>
                        <ProgressBar v-else mode="indeterminate" style="height: 3px"></ProgressBar>
                      </div>
                      <div class="mb-3">
                        <label class="form-label">Religion</label>
                        <Multiselect v-if="!indonesia.religionLoading" v-model="dt_contacts.form.religion"
                                     deselect-label="Can't remove this value"
                                     placeholder="Select Religion"
                                     label="rel_name"
                                     track-by="rel_name"
                                     :options="indonesia.religionList"
                                     :searchable="true" :allow-empty="false"
                        >
                          <template #selection>
                          </template>
                        </Multiselect>
                      </div>
                      <div class="mb-3">
                        <label class="form-label">Birthdate</label>
                        <div class="input-group input-group-merge">
                          <DatePicker v-model="dt_contacts.form.bday" dateFormat="dd/mm/yy" showIcon fluid iconDisplay="input" placeholder="Birthdate?" />
                        </div>
                      </div>
                      <div class="mb-3">
                        <label class="form-label" for="basic-default-address">Address</label>
                        <textarea v-model="dt_contacts.form.address" id="basic-default-address" class="form-control" placeholder="Address"></textarea>
                      </div>
                      <div class="mb-3">
                        <label class="form-label" for="basic-default-message">Others</label>
                        <textarea v-model="dt_contacts.form.others" id="basic-default-message" class="form-control" placeholder="Hi, Do you have a moment to talk Joe?"></textarea>
                      </div>
                      <div class="mb-3">
                        <CrudButton
                            @saveClick="crudContacts"
                            :btn-save-disabled=loading
                            @deleteClick="crudContacts"
                            :btn-delete-disabled=dt_contacts.disabled
                            @clearClick="onContactClear"
                            :record-data=dt_contacts.recordToDelete
                            :loading=loading
                            :message="dt_contacts.message"
                            @messageClose="dt_contacts.message=''"
                            :selected-record="dt_contacts.selectedRecord"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div :class="getTabContentClass(2)" id="navs-top-messages" role="tabpanel">
              <div class="col-xl">
                <div class="card mb-4">
                  <div class="card-header d-flex justify-content-between align-items-center">
                    <h5 class="mb-0">Add Group</h5>
                    <small class="text-muted float-end">Form Group</small>
                  </div>
                  <div class="card-body">
                    <div class="mb-3">
                      <label class="form-label" for="basic-default-groupname">Group Name</label>
                      <input type="text" v-model="dt_groups.form.name" class="form-control" id="basic-default-groupname" placeholder="Group name">
                    </div>
                    <div class="mb-3">
                      <CrudButton
                          @saveClick="crudGroups"
                          :btn-save-disabled=loading
                          @deleteClick="crudGroups"
                          :btn-delete-disabled=dt_groups.disabled
                          @clearClick="onGroupsClear"
                          :record-data=dt_groups.recordToDelete
                          :loading=loading
                          :message="dt_groups.message"
                          @messageClose="dt_groups.message=''"
                          :selected-record="dt_groups.selectedRecord"
                      />
                    </div>
                  </div>
                  <Divider/>
                  <div class="card-datatable table-responsive">
                    <DataTable
                        ref="dtgroups"
                        :dataKey="dt_groups.key"
                        :filters="dt_groups.filters"
                        :globalFilterFields="dt_groups.globalFiltersField"
                        :filterDisplay="dt_groups.filterDisplay"
                        :value="dt_groups.list"
                        :rows="dt_groups.rows"
                        :totalRecords="dt_groups.totalrow"
                        :loading="dt_groups.loading"
                        :lazy="dt_groups.lazy"
                        :rowsPerPageOptions="dt_groups.pageoptions"
                        @page="onPageGroup"
                        loadingIcon="pi pi-spin pi-spinner"
                        tableStyle=""
                        size=""
                        class="overflow-auto w-auto"
                        stripedRows
                        paginator
                        v-model:selection="dt_groups.selected"
                    >
                      <template #header>
                        <div class="flex justify-end">
                          <IconField>
                            <InputIcon>
                              <i class="pi pi-search" />
                            </InputIcon>
                            <InputText v-model="dt_groups.filters['global'].value" placeholder="Keyword Search" @keyup="onfilterGroup" />
                          </IconField>
                        </div>
                      </template>
                      <template #empty> No group found. </template>
                      <template #loading> Loading group data. Please wait. </template>
                      <Column field="no" header="No"></Column>
                      <Column field="g_id" header="ID"></Column>
                      <Column header="Name">
                        <template #body="{ data }">
                          {{ data.g_name }}
                        </template>
                      </Column>
                      <Column field="members"  header="Member"></Column>
                      <Column header="Edit">
                        <template #body="{ data }">
                          <i v-if="data===dt_groups.item" class="bx bx-check"></i>
                          <i v-else @click="doEditGroup('delete',data)" class="bx bx-edit" style="cursor: pointer"></i>
                        </template>
                      </Column>
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
            <div :class="getTabContentClass(3)" id="navs-top-profile" role="tabpanel">
              <div class="col-xl">
                <div class="card mb-4">
                  <div class="card-header d-flex justify-content-between align-items-center">
                    <h5 class="mb-0">Import Contact</h5>
                    <small class="text-muted float-end">Form Import</small>
                  </div>
                  <div class="card-body">
                    <div class="mb-3">
                      <label class="col-md-2 col-form-label">Upload File</label>
                      <span><a href="../assets/files/import_template.xlsx" target="_blank">Template file .xlsx</a></span>
                      <ProgressBar v-if="dt_import.isupload" :value="dt_import.uploadprogress"></ProgressBar>
                      <FileUpload
                          name="attachment"
                          :url="fileuploadUrl"
                          @before-send="beforeUpload"
                          @upload="onUpload"
                          @select="onSelect"
                          @progress="onProgress"
                          :multiple="false"
                          :fileLimit="1"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          :maxFileSize="5000000">
                        <template #empty>
                          <span>Drag and drop files to here to upload.</span>
                        </template>
                      </FileUpload>
                    </div>
                    <div class="mb-3" v-if="dt_import.list.length>0">
                      <Button @click="crudImport('import')" :loading = "dt_import.loading" label="Process" icon="bx bx-import" iconPos="right" />
                    </div>
                    <div class="card-datatable table-responsive">
                      <DataTable
                          :value="dt_import.list"
                          stripedRows
                          paginator
                          :rows="5"
                          :rowsPerPageOptions="[5, 10, 20, 50, 100]"
                      >
                        <template #empty> No data found. </template>
                        <template #loading> Loading import data. Please wait. </template>
                        <Column field="no" header="No"></Column>
                        <Column field="f_name" header="First Name"></Column>
                        <Column field="l_name" header="Last Name"></Column>
                        <Column field="c_name" header="Company"></Column>
                        <Column field="phone_no" header="Phone"></Column>
                        <Column field="email" header="Email"></Column>
                        <Column field="birthdate" header="Birthdate"></Column>
                        <Column field="address" header="Address"></Column>
                        <Column field="others" header="Others"></Column>
                        <Column field="gid" header="Groups"></Column>
                        <Column field="status" header="Status"></Column>
                      </DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, ref} from 'vue'
import ConfirmPopup from 'primevue/confirmpopup';
import ProgressSpinner from 'primevue/progressspinner';
import Toast from 'primevue/toast';
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import 'primeicons/primeicons.css'
import {useStore} from "vuex"
import DashboardServices from "@/services/dashboard.services"
import ProgressBar from 'primevue/progressbar'
import FileUpload from 'primevue/fileupload';
import { FilterMatchMode } from '@primevue/core/api'
import IconField from 'primevue/iconfield'
import InputIcon from 'primevue/inputicon'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Message from 'primevue/message'
import DatePicker from 'primevue/datepicker';
import Multiselect from 'vue-multiselect'
import Divider from 'primevue/divider';
import Chip from 'primevue/chip';
import dashboardServices from "@/services/dashboard.services";
import CrudButton from "@/components/button/CrudButton";
import {useConfirm} from "primevue/useconfirm";
import {useToast} from "primevue/usetoast";
export default {
  name: "ContactPage",
  components:{DataTable,Column,ProgressBar,InputText,InputIcon,IconField,Button,Message,Multiselect,DatePicker,Divider,Chip,ProgressSpinner,FileUpload,CrudButton,ConfirmPopup,Toast},
  setup(){
    const store = useStore()
    const confirm = useConfirm()
    const toast = useToast()
    let tabactive =  ref(0)
    const blocked = ref(true)
    const selectedProduct = ref()
    let indonesia = ref({
      httpresponse: 0,
      response:[],
      success: false,
      provinceList:[],
      provinceData:[],
      cityList:[],
      subList:[],
      religionList:[],
      religionLoading: false,
      subDistrictLoading: false,
      cityLoading: false
    })
    let dt_groups = ref({
      httpresponse: 0,
      response:[],
      success: false,
      key:'group_id',
      loading: false,
      item:[],
      selected:[],
      disabled:false,
      recordToDelete:"",
      selectedRecord:[],
      list:[],
      form:{
        gid:"",
        name:""
      },
      message:"",
      rows:0,
      totalrow:0,
      lazy:true,
      lazyParams:[],
      pageoptions:[5, 10, 20, 50, 100],
      filters:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'group_name': {value: '', matchMode: FilterMatchMode.CONTAINS},
      },
      filterDisplay:"row",
      globalFiltersField:['c_fname','c_lname','c_phone','c_email','c_address'],
    })
    let fileuploadUrl = ref()
    let dt_import = ref({
      httpresponse: 0,
      response:[],
      success: false,
      key:'no',
      loading: false,
      isupload: false,
      uploadprogress:0,
      list:[],
      item:[],
      selected:[],
      form:{
        imgname:"",
        fileAttachment:""
      },
      message:"",
      rows:0,
      totalrow:0,
      lazy:false,
      lazyParams:[],
      pageoptions:[5, 10, 20, 50, 100],
      filters:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'f_name': {value: '', matchMode: FilterMatchMode.CONTAINS},
        'l_name': {value: '', matchMode: FilterMatchMode.CONTAINS},
        'phone_no': {value: '', matchMode: FilterMatchMode.CONTAINS},
      },
      filterDisplay:"row",
      globalFiltersField:['f_name','l_name','phone_no','email','address'],
    })

    let contact = ref({
      httpresponse:[],
      success: false,
      selected:[],
      groupLoading: false,
      deleteLoading: false,
      groupList:[],
      message:"",
      addToGid:"",
    })

    let dt_contacts = ref({
      httpresponse: 0,
      response:[],
      success: false,
      key:'c_id',
      loading: false,
      list:[],
      item:[],
      disabled: false,
      selectedRecord:[],
      recordToDelete:"",
      form:{
        cid:"",
        firstname:"",
        lastname:"",
        company:"",
        phoneno:"",
        email:"",
        province:[],
        city:[],
        subdistrict:[],
        address:"",
        others:"",
        bday:"",
        religion:""
      },
      message:"",
      rows:0,
      totalrow:0,
      lazy:true,
      lazyParams:[],
      pageoptions:[5, 10, 20, 50, 100],
      filters:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'c_fname': {value: '', matchMode: FilterMatchMode.CONTAINS},
        'c_lname': {value: '', matchMode: FilterMatchMode.CONTAINS},
        'c_phone': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
        'c_email': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
        'c_address': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
      },
      filterDisplay:"row",
      globalFiltersField:['c_fname','c_lname','c_phone','c_email','c_address'],
    })

    onMounted(() => {
      crudContacts();
      crudGroups();
      loadGroupList();
      loadIndonesiaLocation();
      loadIndonesiaReligion();
    })

    const onPageContact = (event) => {
      dt_contacts.value.lazyParams = event
      crudContacts('read')
    }

    function getTemplate(){
      return DashboardServices.generateUrl('import_template.xlsx')
    }

    function onfilterContact(event){
      dt_contacts.value.lazyParams = {filters:dt_contacts.value.filters}
      if(event.keyCode === 13){
        dt_contacts.value.lazy = true;
        crudContacts('read');
      }else{
        dt_contacts.value.lazy = false;
      }
    }

    const onPageGroup = (event) => {
      dt_groups.value.lazyParams = event
      crudGroups('read')
    }

    function onfilterGroup(event){
      dt_groups.value.lazyParams = {filters:dt_groups.value.filters}
      if(event.keyCode === 13){
        dt_groups.value.lazy = true;
        crudGroups('read');
      }else{
        dt_groups.value.lazy = false;
      }
    }

    function findReligionById(id){
      let prov = indonesia.value.religionList;
      for(let i = 0; i < prov.length; i++){
        if(prov[i].rel_id.toString() === id.toString()){
          return prov[i]
        }
      }
    }

    function findProvinceById(id){
      let prov = indonesia.value.provinceList;
      for(let i = 0; i < prov.length; i++){
        if(prov[i].id.toString() === id.toString()){
          return prov[i]
        }
      }
    }

    function findCity(id){
      let prov = indonesia.value.cityList;
      for(let i = 0; i < prov.length; i++){
        if(prov[i].cityid === id){
          return prov[i]
        }
      }
    }

    function findSubDistrict(id){
      let prov = indonesia.value.subList;
      for(let i = 0; i < prov.length; i++){
        if(prov[i].id === id){
          return prov[i]
        }
      }
    }

    function doEditGroup(action,item){
      dt_groups.value.item = item
      dt_groups.value.disabled = false
      dt_groups.value.form.gid = item.g_id
      dt_groups.value.form.name = item.g_name
      dt_groups.value.recordToDelete = item.g_name
      dt_groups.value.selectedRecord = item;
    }

    function doEditContact(action,item){
      dt_contacts.value.item = item
      dt_contacts.value.selectedRecord = item
      dt_contacts.value.disabled = false
      dt_contacts.value.form.cid = item.c_id
      dt_contacts.value.form.firstname =item.c_fname
      dt_contacts.value.form.lastname=item.c_lname
      dt_contacts.value.form.company=item.c_company
      dt_contacts.value.form.phoneno=item.c_phone
      dt_contacts.value.form.email=item.c_email
      dt_contacts.value.recordToDelete = item.c_fname
      if(item.c_prov_id != null){
        dt_contacts.value.form.province= findProvinceById(item.c_prov_id)
        showCity()
        if(item.c_city_id != null){
          dt_contacts.value.form.city = findCity(item.c_city_id)
          showSubDistrict()
          if(item.c_dist_id != null){
            dt_contacts.value.form.subdistrict = findSubDistrict(item.c_dist_id)
          }
        }
      }
      dt_contacts.value.form.address=item.c_address
      dt_contacts.value.form.others=item.c_others
      dt_contacts.value.form.bday=item.bday
      if(item.religion != null){
        dt_contacts.value.form.religion = findReligionById(item.religion)
      }
      tabactive.value = 1;
    }

    function changeTab(idx){
      tabactive.value = idx;
    }
    function getTabClass(idx){
      if(tabactive.value === idx){
        return "nav-link active"
      }else{
        return "nav-link"
      }
    }

    function getTabContentClass(idx){
      if(tabactive.value === idx){
        return "tab-pane active show"
      }else{
        return "tab-pane fade";
      }
    }

    function setupDisplay(data,pagination){
      data.value.rows = pagination.rows
      data.value.totalrow = pagination.records
    }

    function loadGroupList(){
      contact.value.groupLoading = true;
      store.dispatch('setLoading',true);
      let data = {}
      let path = '/dashboard/getData/groups';
      DashboardServices.postData(data,path).then(
          (response)=>{
            contact.value.httpresponse = response.data
            if(response.data.status===200){
              contact.value.groupList = response.data.data
              contact.value.success = true;
            }else {
              contact.value.success = false;
            }
            store.dispatch('setLoading',false);
          }
      );
      contact.value.groupLoading = false;
    }

    function loadIndonesiaReligion(){
      indonesia.value.religionLoading = true;
      store.dispatch('setLoading',true);
      let data = {}
      let path = '/dashboard/getData/religion';
      DashboardServices.postData(data,path).then(
          (response)=>{
            indonesia.value.httpresponse = response.data
            if(response.data.status===200){
              indonesia.value.religionList = response.data.data
              indonesia.value.success = true;
            }else {
              indonesia.value.success = false;
            }
            store.dispatch('setLoading',false);
          }
      );
      indonesia.value.religionLoading = false;
    }

    function loadIndonesiaLocation(){
      store.dispatch('setLoading',true);
      let data = {}
      let path = '/dashboard/getData/locationparams';
      DashboardServices.postData(data,path).then(
          (response)=>{
            indonesia.value.httpresponse = response.data
            if(response.data.status===200){
              indonesia.value.provinceList = response.data.data.province
              indonesia.value.provinceData = response.data.data.provincedata
              indonesia.value.success = true;
            }else {
              indonesia.value.success = false;
            }
            store.dispatch('setLoading',false);
          }
      );
    }

    function showSubDistrict(){
      indonesia.value.subDistrictLoading = true
      dt_contacts.value.form.subdistrict = ""
      indonesia.value.subList = dt_contacts.value.form.city.subdist
      indonesia.value.subDistrictLoading = false
    }

    function showCity(){
      indonesia.value.cityLoading = true;
      dt_contacts.value.form.city = ""
      if(dt_contacts.value.form.province != null){
        let prov = indonesia.value.provinceData;
        for(let i = 0; i < prov.length; i++){
          if(prov[i].propid === dt_contacts.value.form.province.id){
            let city = prov[i].citydata;
            if(city.length>0){
              indonesia.value.cityList = city;
            }
          }
        }
      }
      indonesia.value.cityLoading = false;
    }

    function crudGroups(action){
      dt_groups.value.lazy = true
      store.dispatch('setLoading',true)
      dt_groups.value.loading = true
      let data = {
        action:action,
        data: dt_groups.value.form,
        params: dt_groups.value.lazyParams
      };
      let path = '/dashboard/getData/dataGroups';
      DashboardServices.postData(data,path).then(
          (response)=>{
            dt_groups.value.httpresponse = response.data
            if(response.data.status===200){
              dt_groups.value.list = response.data.data
              setupDisplay(dt_groups,response.data.pagination)
              dt_groups.value.message = response.data.message
              dt_groups.value.success = true
              loadGroupList()
            }else {
              dt_groups.value.success = false
            }
            dt_groups.value.loading = false
            store.dispatch('setLoading',false)
          }
      );
    }

    function labelit(text){
      if(text != null){
        if(text.length>0){
          const myArray = text.split(",")
          return myArray
        }else{
          return text;
        }
      }else{
        return ""
      }
    }

    function deleteContact(){
      confirm.require({
        target: event.currentTarget,
        message: 'Are you sure you want to delete contact ?',
        icon: 'pi pi-exclamation-triangle',
        rejectProps: {
          label: 'Cancel',
          severity: 'secondary',
          outlined: true
        },
        acceptProps: {
          label: 'Delete'
        },
        accept: () => {
          contact.value.deleteLoading= true
          dt_contacts.value.form = contact.value.selected
          crudContacts('bulkdelete');
          setTimeout(() => {
            contact.value.deleteLoading= false
          }, 1000);
        },
        reject: () => {
          toast.add({ severity: 'error', summary: 'Canceled', detail: 'Cancel delete', life: 3000 });
        }
      });
    }

    function addToGroup(action){
      contact.value.groupLoading = true;
      store.dispatch('setLoading',true)
      let data = {
        action:action,
        data: {
          selected:contact.value.selected,
          gid:contact.value.addToGid
        },
        params: []
      };
      let path = '/dashboard/getData/addToGroup';
      DashboardServices.postData(data,path).then(
          (response)=>{
            contact.value.httpresponse = response.data
            if(response.data.status===200){
              contact.value.message = response.data.message
              contact.value.success = true
              crudContacts()
              crudGroups()
            }else {
              contact.value.success = false
            }
            contact.value.groupLoading = false;
            store.dispatch('setLoading',false)
          }
      );
    }

    function crudImport(action){
      store.dispatch('setLoading',true)
      dt_import.value.loading = true
      let data = {
        action:action,
        data: dt_import.value.list,
        params: dt_import.value.lazyParams
      };
      let path = '/dashboard/getData/importContact';
      DashboardServices.postData(data,path).then(
          (response)=>{
            dt_import.value.httpresponse = response.data
            if(response.data.status===200){
              dt_import.value.list = response.data.data
              dt_import.value.message = response.data.message
              dt_import.value.success = true
            }else {
              dt_import.value.success = false
            }
            dt_import.value.loading = false
            store.dispatch('setLoading',false)
          }
      );
    }

    function beforeUpload(request){
      let token = dashboardServices.getUserToken();
      request.xhr.setRequestHeader('Authorization', 'Bearer ' + token)
      request.formData.append('paramname',dt_import.value.form.imgname)
      return request
    }

    const onProgress = (event) => {
      dt_import.value.isupload = true
      dt_import.value.uploadprogress = event.progress
    }

    const onUpload = (event) => {
      store.dispatch('setLoading',true)
      let response = event.xhr.response
      const obj = JSON.parse(response)
      dt_import.value.uploadMessage = obj.message
      dt_import.value.message = obj.message
      dt_import.value.list = obj.data
      //crudImport('read')
      store.dispatch('setLoading',false)
    }

    const onSelect = (event) => {
      dt_import.value.isupload = true
      dt_import.value.form.fileAttachment = event.files[0].name
    }

    function onGroupsClear(){
      dt_groups.value.selectedRecord = []
      dt_groups.value.selected = []
      dt_groups.value.item = []
      dt_groups.value.form.gid = ""
      dt_groups.value.form.name = ""
      dt_groups.value.disabled = true
    }

    function onContactClear(){
      contact.value.selected = []
      dt_contacts.value.selectedRecord = []
      dt_contacts.value.item = []
      dt_contacts.value.form.cid = ""
      dt_contacts.value.form.religion = ""
      dt_contacts.value.form.city = ""
      dt_contacts.value.form.subdistrict = ""
      dt_contacts.value.form.bday = ""
      dt_contacts.value.form.province = ""
      dt_contacts.value.form.address = ""
      dt_contacts.value.form.company = ""
      dt_contacts.value.form.firstname = ""
      dt_contacts.value.form.lastname = ""
      dt_contacts.value.form.others = ""
      dt_contacts.value.form.phoneno = ""
      dt_contacts.value.disabled = true
    }

    function groupRemove(dt,groupname){
      let data = {
        action:'removegroup',
        data: {
          dt:dt,
          g:groupname
        },
        params: dt_contacts.value.lazyParams
      };
      let path = '/dashboard/getData/datacontacts';
      DashboardServices.postData(data,path).then(
          (response)=>{
            contact.value.httpresponse = response.data
            if(response.data.status===200){
              contact.value.groupList = response.data.data
              contact.value.success = true;
            }else {
              contact.value.success = false;
            }
            store.dispatch('setLoading',false);
          }
      );
      //contact.value.groupLoading = false;
    }

    function crudContacts(action){
      dt_contacts.value.lazy = true
      store.dispatch('setLoading',true)
      dt_contacts.value.loading = true
      let data = {
        action:action,
        data: dt_contacts.value.form,
        params: dt_contacts.value.lazyParams
      };
      let path = '/dashboard/getData/dataContacts';
      DashboardServices.postData(data,path).then(
          (response)=>{
            dt_contacts.value.httpresponse = response.data
            if(response.data.status===200){
              dt_contacts.value.list = response.data.data
              setupDisplay(dt_contacts,response.data.pagination)
              dt_contacts.value.message = response.data.message
              dt_contacts.value.success = true
            }else {
              dt_contacts.value.success = false
            }
            dt_contacts.value.loading = false
            store.dispatch('setLoading',false)
          }
      );
    }

    onMounted(() => {
      fileuploadUrl.value = DashboardServices.generateUrl('/dashboard/importfile')
    })

    return {
      loading: computed(()=>store.getters.getLoadingStatus),
      showDelete: computed(()=>contact.value.selected.length>0),
      tabactive,
      changeTab,
      getTabClass,
      getTabContentClass,
      blocked,
      onfilterContact,
      onfilterGroup,
      dt_contacts,
      onPageContact,
      onPageGroup,
      crudContacts,
      crudGroups,
      indonesia,
      showCity,
      showSubDistrict,
      dt_groups,
      labelit,
      groupRemove,
      contact,
      addToGroup,
      deleteContact,
      doEditContact,
      doEditGroup,
      dt_import,
      fileuploadUrl,
      beforeUpload,
      onUpload,
      onSelect,
      onProgress,
      onContactClear,
      onGroupsClear,
      selectedProduct,
      getTemplate,
      crudImport,
    }
  }
}
</script>

<style scoped>
::v-deep(.vtl-table .vtl-thead .vtl-thead-th) {
  color: #fff;
  background-color: #42b983;
  border-color: #938f8f;
}

::v-deep(.vtl-table td),
::v-deep(.vtl-table tr) {
  color: #000;
  border-color: #938f8f;
}

::v-deep(.vtl-paging-info) {
  color: rgb(172, 0, 0);
}

::v-deep(.vtl-paging-count-label),
::v-deep(.vtl-paging-page-label) {
  color: rgb(172, 0, 0);
}

::v-deep(.vtl-paging-pagination-page-link) {
  border-color: #000;
}


</style>