<template>
  <aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme">
    <LogoBrand :center=false></LogoBrand>
    <div class="menu-inner-shadow"></div>
    <ul class="menu-inner py-1">
      <MenuItem v-for="(item,idx) in menusdata"
                :key="idx"
                :title=item.title
                :activemenu=activeMenu
                @activeChange="activeChange"
                :linkto=item.linkto
                :target=item.target
                :badge=item.badge
                :badgetype=item.badgetype
                :icon=item.icon
                :menuchild=item.child
                :isparent=item.isparent
      ></MenuItem>
    </ul>
  </aside>
</template>

<script>
import LogoBrand from "@/components/LogoBrand";
import MenuItem from "@/components/menu/MenuItem";
import {ref} from "vue";
export default {
  name: "MenusPage",
  components: {MenuItem, LogoBrand},
  setup(){
    const menusdata = [
      {
        "linkto": "/dashboard",
        "target": "_blank",
        "title": "Home",
        "badge": "0",
        "icon":'tf-icons bx bx-home-circle',
        "badgetype": 0,
        "isparent": false,
        "child": []
      },
      {
        "linkto": "/dashboard",
        "target": "_blank",
        "title": "Broadcast",
        "badge": "0",
        "icon":'tf-icons bx bx-message',
        "badgetype": 0,
        "isparent": true,
        "child": [
          {
            "linkto": "/pages/broadcastsms",
            "target": "_blank",
            "title": "SMS",
            "badge": "0",
            "badgetype": 0,
            "icon":"",
            "isparent": false
          },
          {
            "linkto": "/pages/broadcastwa",
            "target": "_blank",
            "title": "Whatsapp",
            "badge": "0",
            "badgetype": 0,
            "icon":"",
            "isparent": false
          },
          {
            "linkto": "/pages/listcampaign",
            "target": "_blank",
            "title": "List Campaign",
            "badge": "0",
            "badgetype": 0,
            "icon":"",
            "isparent": false
          },
          {
            "linkto": "/pages/imagelist",
            "target": "_blank",
            "title": "Image List",
            "badge": "0",
            "badgetype": 0,
            "icon":"",
            "isparent": false
          },
        ]
      },
      {
      "linkto": "/pages/customers",
      "target": "_blank",
      "title": "Customers",
      "badge": "0",
      "icon":'tf-icons bx bxs-contact',
      "badgetype": 0,
      "isparent": true,
      "child": [
        {
          "linkto": "/pages/contactlist",
          "target": "_blank",
          "title": "List Contacts",
          "badge": "0",
          "badgetype": 0,
          "icon":"",
          "isparent": false
        },
        {
          "linkto": "/pages/groups",
          "target": "_blank",
          "title": "Contact Groups",
          "badge": "0",
          "badgetype": 0,
          "icon":"",
          "isparent": false
        }
      ]
      },
      {
        "linkto": "/pages/reports",
        "target": "_blank",
        "title": "Reports",
        "badge": "0",
        "icon":'tf-icons bx bxs-report',
        "badgetype": 0,
        "isparent": true,
        "child": [
          {
            "linkto": "/pages/reportdetails",
            "target": "_blank",
            "title": "Report Detail",
            "badge": "0",
            "badgetype": 0,
            "icon":"",
            "isparent": false
          },
        ]
      },
      {
        "linkto": "/pages/settings",
        "target": "_blank",
        "title": "Settings",
        "badge": "0",
        "icon":'tf-icons bx bx-cog',
        "badgetype": 0,
        "isparent": true,
        "child": [
          {
            "linkto": "/pages/changepassword",
            "target": "_blank",
            "title": "Change Password",
            "badge": "0",
            "badgetype": 0,
            "icon":"",
            "isparent": false
          },
          {
            "linkto": "/pages/usermanagement",
            "target": "_blank",
            "title": "User Management",
            "badge": "0",
            "badgetype": 0,
            "icon":"",
            "isparent": false
          },
          {
            "linkto": "/pages/template",
            "target": "_blank",
            "title": "Template",
            "badge": "0",
            "badgetype": 0,
            "icon":"",
            "isparent": false
          },
          {
            "linkto": "/pages/broadcastsetting",
            "target": "_blank",
            "title": "Job Config",
            "badge": "0",
            "badgetype": 0,
            "icon":"",
            "isparent": false
          },
        ]
      },
      {
        "linkto": "/logout",
        "target": "_blank",
        "title": "Logout",
        "badge": "0",
        "icon":'tf-icons bx bx-log-out',
        "badgetype": 0,
        "isparent": false,
        "child": []
      },
    ];
    let activeMenu = ref('Test');
    function activeChange(param){
      activeMenu.value = param;
    }
    function changeView(param){
      console.log(' COBA '+param);
      //emit('changeView',param);
    }
    return {menusdata,activeMenu,activeChange,changeView}
  }
}
</script>

<style scoped>

</style>