<template>
  <div class="col-md-6">
    <div class="card h-100">
      <div class="card-header d-flex align-items-center justify-content-between">
        <h5 class="card-title m-0 me-2">{{boxtitle}}</h5>
      </div>
      <div class="card-body">
        <ul class="p-0 m-0" v-for="(item,idx) in data" :key="idx">
          <li class="d-flex mb-4 pb-1 border-bottom">
            <div class="avatar flex-shrink-0 me-3">
              <i v-if="item.t_mode==='wa'" class='bx bxl-whatsapp' ></i>
              <i v-else class='bx bx-message-rounded-detail' ></i>
            </div>
            <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
              <div class="me-2">
                <small class="text-primary text-uppercase d-block mb-1">{{item.t_mode}}</small>
                <h6 class="mb-0">{{item.destno}}</h6>
              </div>
              <div class="user-progress d-flex align-items-center gap-1">
                <h6 class="mb-0">{{item.created_date}}</h6>
                <span v-if="item.t_status==='99'" class="badge bg-secondary">Pending</span>
                <span v-else-if="item.t_status==='00'" class="badge bg-success">Terkirim</span>
                <span v-else-if="item.t_status==='0'" class="badge bg-success">Terkirim</span>
                <span v-else-if="item.t_status==='97'" class="badge bg-danger">Failed</span>
                <span v-else-if="item.t_status==='98'" class="badge bg-success">Processing</span>
                <span v-else class="badge bg-warning">Queueing</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrxComponentBox",
  props:{
    boxtitle:String,
    data:Array
  }
}
</script>

<style scoped>

</style>