<template>
  <div class="container-xxl flex-grow-1 container-p-y text-start">
    <h4 class="py-3 mb-4">
      <span class="text-muted fw-light">Broadcast via</span> Short Message Service
    </h4>
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <h5 class="card-header">Compose SMS Messages</h5>
          <div class="card-body">
            <div class="mb-3">
              <label class="form-label">Contact Type</label>
              <div class="ml-5">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="dataTemplate.form.contactType" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="individual">
                  <label class="form-check-label ml-3" for="inlineRadio2">Individual Contact</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="dataTemplate.form.contactType" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="contact">
                  <label class="form-check-label ml-3" for="inlineRadio2">From Contact List</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="dataTemplate.form.contactType" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="group">
                  <label class="form-check-label ml-3" for="inlineRadio2">By Group</label>
                </div>
              </div>
            </div>
            <div class="mb-3" v-if="dataTemplate.form.contactType==='individual'">
              <label for="exampleFormControlInput1" class="form-label">Phone Number</label>
              <Transition name="slide-fade">
              <InputNumber v-model="dataTemplate.form.phonenumber" maxLength=20 class="form-control" id="exampleFormControlInput1" placeholder="6281xxx"></InputNumber>
              </Transition>
            </div>
            <div class="mb-3" v-if="dataTemplate.form.contactType==='group'">
              <label for="exampleFormControlInput1" class="form-label">Group Name</label>
              <Multiselect v-model="dataTemplate.form.gid"
                           deselect-label="Click to remove this value"
                           placeholder="Select group"
                           label="g_name"
                           track-by="g_name"
                           :options="dataTemplate.groupList"
                           openDirection="below"
                           :searchable="true"
                           :allow-empty="true">
                <template #selection>
                </template>
              </Multiselect>
            </div>
            <div class="mb-3" v-if="dataTemplate.form.contactType==='contact'">
              <label for="exampleFormControlInput1" class="form-label">Contact Name (Max 20)</label>
              <Multiselect v-model="dataTemplate.form.gid"
                           deselect-label="Click to remove this value"
                           tag-placeholder="Add this as new tag"
                           placeholder="Select contact"
                           label="fullnama"
                           :max="20"
                           track-by="fullnama"
                           :multiple="true"
                           :taggable="true"
                           :options="dataTemplate.contactList"
                           openDirection="below"
                           :searchable="true"
                           :allow-empty="true">
                <template #selection>
                </template>
              </Multiselect>
            </div>
            <div class="mb-3">
              <label for="FormControlInputCampaign" class="form-label">Campaign ID</label>
              <input type="text" maxlength="100" id="FormControlInputCampaign" v-model="dataTemplate.form.cid" class="form-control" placeholder="Campaign ID (optional)">
            </div>
            <div class="mb-3">
              <label for="exampleFormControlSelect2" class="form-label">Sender Name</label>
              <Multiselect v-model="dataTemplate.form.senderid"
                           id = "exampleFormControlSelect2"
                           deselect-label="Can't remove this value"
                           placeholder="Select sender"
                           label="s_name"
                           track-by="s_name"
                           :options="dataTemplate.senderList"
                           openDirection="below"
                           :searchable="true" :allow-empty="false">
                <template #selection>
                </template>
              </Multiselect>
            </div>
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">Message</label>
              <div class="input-group">
                <span class="input-group-text"><span class="widget widget-lg">{{msgcounter}}/{{maxmessage}} of {{countmsg}}</span></span>
                <textarea class="form-control" v-model="dataTemplate.form.message" id="exampleFormControlTextarea1" rows="5"></textarea>
              </div>
            </div>
            <div class="mb-3">
              <label for="html5-datetime-local-input" class="col-md-2 col-form-label">Schedule</label>
              <div class="col-md-10">
                <input class="form-control" v-model="dataTemplate.form.schedule" type="datetime-local" placeholder="2021-06-18T12:30:00" id="html5-datetime-local-input">
              </div>
            </div>
            <div class="mb-3">
              <Message v-show="dataTemplate.message.length>0">{{dataTemplate.message}}</Message>
              <Button v-if="!loading" label="Save" :disabled="loading" @click="crudTemplate('save')" icon="pi pi-check" iconPos="right" class="m-2"/>
              <ProgressSpinner v-else style="width: 50px; height: 50px" strokeWidth="8" fill="transparent" animationDuration=".5s" aria-label="Custom ProgressSpinner" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import InputNumber from "@/components/input/InputNumber";
import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";
import DashboardServices from "@/services/dashboard.services";
import Message from 'primevue/message'
import Button from 'primevue/button'
import ProgressSpinner from 'primevue/progressspinner';
export default {
  name: "BroadcastSMS",
  components: {Multiselect,InputNumber,Message,Button,ProgressSpinner},
  setup(){
    const store = useStore()
    let phonenumber = ref("")
    let maxmessage = ref(150)
    let dataTemplate = ref({
      httpresponse:[],
      success: false,
      selected:[],
      groupLoading: false,
      attachmentLoading: false,
      senderLoading: false,
      templateLoading: false,
      contactLoading: false,
      templateList:[],
      contactList:[],
      groupList:[],
      attachmentList:[],
      senderList:[],
      lazyParams:[],
      isupload: false,
      uploadprogress:0,
      uploadMessage:"",
      form: {
        messageType:'sms',
        contactType: "individual",
        phonenumber: "",
        gid:"",
        cid:"",
        senderid:"",
        schedule:"",
        templateid:"",
        templateValue:[],
        message:"",
        image:"",
        fileAttachment:""
      },
      message:"",
      addToGid:""
    })

    function loadContactList(){
      dataTemplate.value.contactLoading = true;
      store.dispatch('setLoading',true);
      let data = {
        type:'wa'
      }
      let path = '/dashboard/getData/dataContacts';
      DashboardServices.postData(data,path).then(
          (response)=>{
            dataTemplate.value.httpresponse = response.data
            if(response.data.status===200){
              dataTemplate.value.contactList = response.data.data
              dataTemplate.value.success = true;
            }else {
              dataTemplate.value.success = false;
            }
            store.dispatch('setLoading',false);
          }
      );
      dataTemplate.value.contactLoading = false;
    }

    function loadGroupList(){
      dataTemplate.value.groupLoading = true
      store.dispatch('setLoading',true)
      let data = {}
      let path = '/dashboard/getData/groups';
      DashboardServices.postData(data,path).then(
          (response)=>{
            dataTemplate.value.httpresponse = response.data
            if(response.data.status===200){
              dataTemplate.value.groupList = response.data.data
              dataTemplate.value.success = true
            }else {
              dataTemplate.value.success = false
            }
            store.dispatch('setLoading',false)
          }
      )
      dataTemplate.value.groupLoading = false
    }

    function crudTemplate(action){
      store.dispatch('setLoading',true)
      let data = {
        action:action,
        data: dataTemplate.value.form,
        params: dataTemplate.value.lazyParams
      };
      let path = '/dashboard/getData/addtask';
      DashboardServices.postData(data,path).then(
          (response)=>{
            dataTemplate.value.httpresponse = response.data
            if(response.data.status===200){
              dataTemplate.value.message = response.data.message
              dataTemplate.value.success = true
            }else {
              dataTemplate.value.success = false
            }
            dataTemplate.value.loading = false
            store.dispatch('setLoading',false)
          }
      );
    }

    function loadSenderList(){
      dataTemplate.value.senderLoading = true;
      store.dispatch('setLoading',true);
      let data = {
        type:'sms'
      }
      let path = '/dashboard/getData/sender';
      DashboardServices.postData(data,path).then(
          (response)=>{
            dataTemplate.value.httpresponse = response.data
            if(response.data.status===200){
              dataTemplate.value.senderList = response.data.data
              dataTemplate.value.success = true;
            }else {
              dataTemplate.value.success = false;
            }
            store.dispatch('setLoading',false);
          }
      );
      dataTemplate.value.senderLoading = false;
    }

    function getClass(condition){
      if(dataTemplate.value.form.contactType===condition){
        return "collapse show"
      }else{
        return "collapse"
      }
    }

    function setTel(v) {
      const val = v.target.value.replace(/[^0-9]/g, "");
      phonenumber.value = val;
      /*this.tel = v.target.value = v.target.value.replace(/[^0-9]/g, "");*/
    }
    const countmsg = computed(()=>{
      let x = Math.ceil(dataTemplate.value.form.message.length / 150)
      if(x>1){
        return x+' messages'
      }else{
        return x+' message'
      }
    })

    onMounted(() => {
      loadContactList()
      loadGroupList()
      loadSenderList()
    })

    return {
      loading: computed(()=>store.getters.getLoadingStatus),
      getClass,
      maxmessage,
      countmsg,
      phonenumber,
      dataTemplate,
      msgcounter:computed(() => {return dataTemplate.value.form.message.length}),
      setTel,
      crudTemplate
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>

</style>