<template>
  <Dialog v-model:visible="visibleTemplate" :dismissableMask=true modal header="View Template" :style="{ width: '25rem' }">
    <div class="flex items-center gap-4 mb-4">
      {{currentTemplate}}
    </div>
  </Dialog>
  <div class="container-xxl flex-grow-1 container-p-y text-start">
    <h4 class="py-3 mb-4">
      <span class="text-muted fw-light">Report</span> Details
    </h4>
    <div class="row">
      <div class="card">
        <div class="card-body">
          <div class="card-datatable table-responsive">
            <DataTable
                ref="dt"
                :dataKey="dt_report.key"
                :filters="dt_report.filters"
                :globalFilterFields="dt_report.globalFiltersField"
                :filterDisplay="dt_report.filterDisplay"
                :value="dt_report.list"
                :rows="dt_report.rows"
                :totalRecords="dt_report.totalrow"
                :loading="dt_report.loading"
                :lazy="dt_report.lazy"
                :rowsPerPageOptions="dt_report.pageoptions"
                @page="onPage"
                loadingIcon="pi pi-spin pi-spinner"
                tableStyle=""
                size=""
                class="overflow-auto w-auto h-25"
                stripedRows
                paginator
            >
              <template #header>
                <div style="text-align: left">
                  <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)" />
                </div>
              </template>
              <template #empty> No data found. </template>
              <template #loading> Loading broadcast data. Please wait. </template>
              <Column field="no" header="No"></Column>
              <Column field="s_name" header="Sender"></Column>
              <Column field="scd_date_st" header="Schedule"></Column>
              <Column field="send_date" header="Sent Date"></Column>
              <Column header="Status">
                <template #body="{ data }">
                  <span v-if="data.t_status==='99'" class="badge bg-secondary">Pending</span>
                  <span v-else-if="data.t_status==='00'" class="badge bg-success">Terkirim</span>
                  <span v-else-if="data.t_status==='0'" class="badge bg-success">Terkirim</span>
                  <span v-else-if="data.t_status==='97'" class="badge bg-danger">Failed</span>
                  <span v-else-if="data.t_status==='98'" class="badge bg-success">Processing</span>
                  <span v-else class="badge bg-warning">Queueing</span>
                </template>
              </Column>
              <Column header="Type">
                <template #body="{ data }">
                  <span class="badge rounded-pill bg-primary" v-if="data.s_type==='wa'">{{ data.s_type }}</span>
                  <span v-else class="badge rounded-pill bg-secondary">{{ data.s_type }}</span>
                </template>
              </Column>
              <Column field="t_name" header="Template ID"></Column>
              <Column field="template_name" header="Template Name"></Column>
              <Column header="Template" class="h-25">
                <template #body="{ data }">
                  <span v-if="data.t_code !=null" @click="showTemplate(data.t_code)" style="cursor: pointer" class="badge bg-primary">Show</span>
                </template>
              </Column>
              <Column header="Message">
                <template #body="{ data }">
                  <span v-if="data.t_code !=null" @click="showTemplate(data.t_joinmsg)" style="cursor: pointer" class="badge bg-primary">Show</span>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed, onMounted, ref} from "vue";
import { FilterMatchMode } from '@primevue/core/api'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import DashboardServices from "@/services/dashboard.services";
import Button from 'primevue/button'
import Dialog from 'primevue/dialog';
export default {
  name: "ReportDetails",
  components: {DataTable,Column,Button,Dialog},
  setup(){
    const store = useStore()
    let visibleTemplate = ref(false)
    let currentTemplate = ref("")
    const dt = ref();
    let dt_report = ref({
      httpresponse: 0,
      response:[],
      success: false,
      key:'group_id',
      loading: false,
      form:{
        name:"",
        code:"",
        image:false
      },
      list:[],
      message:"",
      rows:0,
      totalrow:0,
      lazy:true,
      lazyParams:[],
      pageoptions:[5, 10, 20, 50, 100],
      filters:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'group_name': {value: '', matchMode: FilterMatchMode.CONTAINS},
      },
      filterDisplay:"row",
      globalFiltersField:['c_fname','c_lname','c_phone','c_email','c_address'],
    })

    const onPage = (event) => {
      dt_report.value.lazyParams = event
      getReport('read')
    }

    function setupDisplay(data,pagination){
      data.value.rows = pagination.rows
      data.value.totalrow = pagination.records
    }

    function getReport(action){
      dt_report.value.lazy = true
      store.dispatch('setLoading',true)
      dt_report.value.loading = true
      let data = {
        action:action,
        data: dt_report.value.form,
        params: dt_report.value.lazyParams
      };
      let path = '/dashboard/getData/report';
      DashboardServices.postData(data,path).then(
          (response)=>{
            dt_report.value.httpresponse = response.data
            if(response.data.status===200){
              dt_report.value.list = response.data.data
              setupDisplay(dt_report,response.data.pagination)
              dt_report.value.message = response.data.message
              dt_report.value.success = true
            }else {
              dt_report.value.success = false
            }
            dt_report.value.loading = false
            store.dispatch('setLoading',false)
          }
      );
    }

    const exportCSV = () => {
      dt.value.exportCSV();
    };

    function showTemplate (code){
      visibleTemplate.value =!visibleTemplate.value
      currentTemplate.value = code
    }

    onMounted(() => {
      getReport('read')
    })

    return{
      loading: computed(()=>store.getters.getLoadingStatus),
      showTemplate,
      visibleTemplate,
      currentTemplate,
      dt_report,
      dt,
      onPage,
      exportCSV
    }
  }
}
</script>

<style scoped>

</style>