<template>
  <div :class="menumode">
    <div class="layout-container">
      <MenusPage></MenusPage>
      <div class="layout-page">
        <TopNavBar></TopNavBar>
        <div class="content-wrapper">
          <ContactPage v-if="checkPage('contactlist')"></ContactPage>
          <GroupsPage v-else-if="checkPage('groups')"></GroupsPage>
          <BroadcastSMS v-else-if="checkPage('broadcastsms')"></BroadcastSMS>
          <BroadcastWhatsapp  v-else-if="checkPage('broadcastwa')"></BroadcastWhatsapp>
          <ChangePassword v-else-if="checkPage('changepassword')"></ChangePassword>
          <ReportDetails v-else-if="checkPage('reportdetails')"></ReportDetails>
          <CreateCampaignPage v-else-if="checkPage('listcampaign')"></CreateCampaignPage>
          <ImageListPage v-else-if="checkPage('imagelist')"></ImageListPage>
          <SettingPage v-else-if="checkPage('broadcastsetting')"></SettingPage>
          <BroadcastTamplatePage v-else-if="checkPage('template')"></BroadcastTamplatePage>
          <UserManagement v-else-if="checkPage('usermanagement')"></UserManagement>
          <LandingPages v-else></LandingPages>
        </div>
      </div>
    </div>
    <div class="layout-overlay"></div>
  </div>
</template>

<script>
import MenusPage from "@/components/menu/Menus";
import TopNavBar from "@/components/searchbar/TopNavBar";
import LandingPages from "@/components/pages/LandingPages";
import ContactPage from "@/components/pages/ContactPage";
import {useRoute} from "vue-router";
import {computed} from "vue";
import {useStore} from "vuex";
import BroadcastSMS from "@/components/pages/BroadcastSMS";
import BroadcastWhatsapp from "@/components/pages/BroadcastWhatsapp";
import ChangePassword from "@/components/pages/ChangePassword";
import ReportDetails from "@/components/pages/ReportDetails";
import CreateCampaignPage from "@/components/pages/CreateCampaignPage";
import ImageListPage from "@/components/pages/ImageListPage";
import GroupsPage from "@/components/pages/GroupsPage";
import SettingPage from "@/components/pages/SettingPage";
import BroadcastTamplatePage from "@/components/pages/BroadcastTamplatePage";
import UserManagement from "@/components/pages/UserManagement";
export default {
  name: "MainDashboardPage",
  components: {
    UserManagement,
    BroadcastTamplatePage,
    SettingPage,
    GroupsPage,
    ImageListPage,
    CreateCampaignPage,
    ReportDetails,
    ChangePassword,
    BroadcastWhatsapp, BroadcastSMS, MenusPage, ContactPage, LandingPages, TopNavBar},
  setup(){
    const store = useStore();
    const route = useRoute();
    const currpage = computed(() => {
      if(route.params.page != undefined){
        return route.params.page.toLowerCase();
      }else{
        return '';
      }
    });
    const currquery = computed(() => {
      return route.query;
    });
    function checkPage(page){
      if(currpage.value.toLowerCase()===page.toLowerCase()){
        return true;
      }else{
        return false
      }
    }
    function changeView(){
    }
    return {
      menumode: computed(() => {
        if(store.state.leftmenumode===true){
          return "layout-wrapper layout-content-navbar layout-menu-expanded layout-transitioning";
        }else{
          return "layout-wrapper layout-content-navbar layout-transitioning";
        }
      }),
      checkPage,currpage,route,currquery, changeView
    }
  }
}
</script>

<style scoped>

</style>