<template>
  <div class="accordion mt-3" id="accordionExample">
    <div class="card accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button type="button" @click="togle" :class="getButtonClass" data-bs-toggle="collapse" data-bs-target="#accordionOne" aria-expanded="true" aria-controls="accordionOne">
            <slot name="title"></slot>
        </button>
      </h2>
      <div id="accordionOne" :class="getBodyClass" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {computed, ref} from "vue";
export default {
  name: "AccordionPanel",
  props:{
    title: String,
    collapsed: Boolean
  },
  setup(props){
    let collap = ref(true);
    if(props.collapsed===true){
      collap.value = !collap.value
    }
    function togle(){
      collap.value = !collap.value;
    }
    return{
      togle,
      getButtonClass:computed(() => {
        if(collap.value===true){
          return 'accordion-button';
        }else{
          return 'accordion-button collapsed';
        }
      }),
      getBodyClass:computed(() => {
        if(collap.value===true){
          return 'accordion-collapse collapse';
        }else{
          return 'accordion-collapse collapse show';
        }
      })
    }
  }
}
</script>

<style scoped>

</style>