<template>
  <Dialog v-model:visible="visible" :dismissableMask=true modal header="Campaign preview" :style="{ width: '25rem' }">
    <div class="flex items-center gap-4 mb-4">
      <WaScreen
          :image=getLinkImage(dataPreview.file_code)
          :name="dataPreview.s_name"
          :watext="dataPreview.t_joinmsg"></WaScreen>
    </div>
  </Dialog>
  <Dialog v-model:visible="visibleTemplate" :dismissableMask=true modal header="View Template" :style="{ width: '25rem' }">
    <div class="flex items-center gap-4 mb-4">
      {{currentTemplate}}
    </div>
  </Dialog>
  <div class="container-xxl flex-grow-1 container-p-y text-start">
    <h4 class="py-3 mb-4">
      <span class="text-muted fw-light">List /</span> Campaign
    </h4>
    <div class="row">
      <div class="col-md-12">
        <ProgressBar v-if="loading" mode="indeterminate" style="height: 2px"></ProgressBar>
        <div class="nav-align-top mb-4">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item" role="presentation">
              <button type="button" :class=getTabClass(0) @click="changeTab(0)" role="tab" data-bs-toggle="tab" data-bs-target="#navs-top-profile" aria-controls="navs-top-profile" aria-selected="false" tabindex="-1">
                Campaign List
              </button>
            </li>
          </ul>
          <div class="tab-content">
            <div :class="getTabContentClass(0)" id="navs-top-home" role="tabpanel">
              <div class="card-datatable table-responsive">
                <DataTable
                    v-model:expandedRows="expandedRows"
                    ref="dt"
                    dataKey="campaignid"
                    :filters="dt_campaign.filters"
                    :globalFilterFields="dt_campaign.globalFiltersField"
                    :value="dt_campaign.list"
                    :rows="dt_campaign.rows"
                    :totalRecords="dt_campaign.totalrow"
                    :loading="dt_campaign.loading"
                    :lazy="dt_campaign.lazy"
                    :rowsPerPageOptions="dt_campaign.pageoptions"
                    @page="onPageCampaign"
                    loadingIcon="pi pi-spin pi-spinner"
                    tableStyle="min-width: 50rem"
                    size=""
                    class="overflow-auto w-auto"
                    stripedRows
                    paginator
                >
                  <template #empty> No campaign found. </template>
                  <template #loading> Loading contact data. Please wait. </template>
                  <Column field="no" header="No" headerStyle="width: 3rem"></Column>
                  <Column expander style="width: 5rem" />
                  <Column header="Type" >
                    <template #body="{ data }">
                      <span v-if="data.t_mode==='wa'" class="badge bg-primary">{{data.t_mode}}</span>
                      <span v-else-if="data.t_mode==='sms'" class="badge bg-info">{{data.t_mode}}</span>
                      <span v-else class="badge bg-secondary">{{data.t_mode}}</span>
                    </template>
                  </Column>
                  <Column header="Campaign ID" style="width: 5%" class="overflow-auto">
                    <template #body="{ data }">
                      <span class="overflow-auto">{{data.campaignid}}</span>
                    </template>
                  </Column>
                  <Column field="s_name" header="Sender" ></Column>
                  <Column field="dest" header="Destination" ></Column>
                  <Column field="scd_date_st" header="Schedule" ></Column>
                  <Column field="templatename" header="Template Name" ></Column>
                  <Column field="t_code" header="Template" >
                    <template #body="{ data }">
                      <span v-if="data.t_code !=null" @click="showTemplate(data.t_code)" style="cursor: pointer" class="badge bg-primary">Show</span>
                    </template>
                  </Column>
                  <Column field="t_joinmsg" header="Message" >
                    <template #body="{ data }">
                      <span v-if="data.t_code !=null" @click="showTemplate(data.t_joinmsg)" style="cursor: pointer" class="badge bg-primary">Show</span>
                    </template>
                  </Column>
                  <Column header="View" >
                    <template #body="{ data }">
                      <button type="button" @click="doPreview(data)" class="btn rounded-pill btn-info">View</button>
                    </template>
                  </Column>
                  <template #expansion="slotProps">
                    <div class="p-4">
                      <h5>Task status for {{ slotProps.data.campaignid }}</h5>
                      <DataTable :value="slotProps.data.tasklist">
                        <Column field="destno" header="Phone Number" sortable></Column>
                        <Column field="send_date" header="Send Date" sortable></Column>
                        <Column header="Status" sortable>
                          <template #body="{ data }">
                            <span v-if="data.t_status==='99'" class="badge bg-secondary">Pending</span>
                            <span v-else-if="data.t_status==='00'" class="badge bg-success">Terkirim</span>
                            <span v-else-if="data.t_status==='0'" class="badge bg-success">Terkirim</span>
                            <span v-else-if="data.t_status==='97'" class="badge bg-danger">Failed</span>
                            <span v-else-if="data.t_status==='98'" class="badge bg-success">Processing</span>
                            <span v-else class="badge bg-warning">Queueing</span>
                          </template>
                        </Column>
                      </DataTable>
                    </div>
                  </template>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";
import { FilterMatchMode } from '@primevue/core/api'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import 'primeicons/primeicons.css'
import DashboardServices from "@/services/dashboard.services";
import ProgressBar from 'primevue/progressbar'
import Dialog from 'primevue/dialog';
import WaScreen from "@/components/whatsappscreen/WaScreen";
export default {
  name: "CreateCampaignPage",
  components:{WaScreen, DataTable,Column,ProgressBar,Dialog},
  setup(){
    const store = useStore()
    let tabactive =  ref(0)
    let visible = ref(false)
    let visibleTemplate = ref(false)
    let currentTemplate = ref("")
    let dataPreview = ref([])
    let expandedRows = ref([])
    let dt_campaign = ref({
      httpresponse: 0,
      response:[],
      success: false,
      key:'c_id',
      loading: false,
      list:[],
      form:{
        cid:"",
        firstname:"",
        lastname:"",
        company:"",
        phoneno:"",
        email:"",
        province:[],
        city:[],
        subdistrict:[],
        address:"",
        others:"",
        bday:"",
        religion:""
      },
      message:"",
      rows:0,
      totalrow:0,
      lazy:true,
      lazyParams:[],
      pageoptions:[5, 10, 20, 50, 100],
      filters:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'c_fname': {value: '', matchMode: FilterMatchMode.CONTAINS},
        'c_lname': {value: '', matchMode: FilterMatchMode.CONTAINS},
        'c_phone': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
        'c_email': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
        'c_address': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
      },
      filterDisplay:"row",
      globalFiltersField:['c_fname','c_lname','c_phone','c_email','c_address'],
    })

    const onPageCampaign = (event) => {
      dt_campaign.value.lazyParams = event
      crudCampaign('read')
    }

    function changeTab(idx){
      tabactive.value = idx;
    }

    function getimage(code){
      if(code != null){
        return DashboardServices.generateUrl('/viewimage/'+code)
      }else{
        return ""
      }
    }

    function getTabClass(idx){
      if(tabactive.value === idx){
        return "nav-link active"
      }else{
        return "nav-link"
      }
    }

    function getTabContentClass(idx){
      if(tabactive.value === idx){
        return "tab-pane active show"
      }else{
        return "tab-pane fade";
      }
    }

    function setupDisplay(data,pagination){
      data.value.rows = pagination.rows
      data.value.totalrow = pagination.records
    }

    function doPreview(data){
      visible.value = true;
      dataPreview.value = data;
    }

    function crudCampaign(action){
      dt_campaign.value.lazy = true
      store.dispatch('setLoading',true)
      dt_campaign.value.loading = true
      let data = {
        action:action,
        data: dt_campaign.value.form,
        params: dt_campaign.value.lazyParams
      };
      let path = '/dashboard/getData/dataCampaign';
      DashboardServices.postData(data,path).then(
          (response)=>{
            dt_campaign.value.httpresponse = response.data
            if(response.data.status===200){
              dt_campaign.value.list = response.data.data
              setupDisplay(dt_campaign,response.data.pagination)
              dt_campaign.value.message = response.data.message
              dt_campaign.value.success = true
            }else {
              dt_campaign.value.success = false
            }
            dt_campaign.value.loading = false
            store.dispatch('setLoading',false)
          }
      );
    }

    function showTemplate (code){
      visibleTemplate.value =!visibleTemplate.value
      currentTemplate.value = code
    }

    onMounted(() => {
      crudCampaign();
    })

    return{
      loading: computed(()=>store.getters.getLoadingStatus),
      showTemplate,
      getLinkImage: getimage,
      getTabClass,
      changeTab,
      getTabContentClass,
      dt_campaign,
      onPageCampaign,
      doPreview,
      visible,
      visibleTemplate,
      dataPreview,
      expandedRows,
      currentTemplate
    }
  }
}
</script>

<style scoped>

</style>