<template>
  <input type="text" :value="modelValue" :maxlength="maxLength" @input="setTel" class="form-control" id="exampleFormControlInput1" :placeholder=placeholder>
</template>

<script>
import {} from 'vue'
export default {
  name: "InputNumber",
  props:{
    placeholder: String,
    modelValue: String,
    maxLength: String
  },
  setup(props,{emit}){
    function setTel(v) {
      const tmp = v.target.value;
      const val = tmp.replace(/[^0-9]/g, "")
      v.target.value = ""
      emit('update:modelValue', val)
    }
    return{
      setTel
    }
  }
}
</script>

<style scoped>

</style>