<template>
    <div class="button-group" v-if="!loading">
      <Toast />
      <ConfirmPopup></ConfirmPopup>
      <Message closable class="mb-1" v-show="message.length>0" @close="onMessageClose" @life-end="onMessageClose" :life=10000>{{message}}</Message>
      <Button v-if="!isupdate"
          @click=onSaveButtonClick
          :loading="saveBtnLoading"
          label="Save"
          icon="pi pi-check"
          iconPos="right"
          class="me-2"
          :disabled=saveBtnLoading
      />
      <Button v-else
          @click=onUpdateButtonClick($event)
          :loading="updateBtnLoading"
          label="Update"
          icon="pi pi-check"
          iconPos="right"
          class="me-2"
          :disabled=updateBtnLoading
      />
      <Button
          @click=onDeleteButtonClick($event)
          :loading="deleteBtnLoading"
          label="Delete"
          icon="bx bx-trash-alt"
          iconPos="right"
          class="me-2"
          severity="danger"
          :disabled=btnDeleteDisabled
      />
      <Button
          @click=onClearButtonClick($event)
          :loading="clearBtnLoading"
          label="Clear"
          icon="bx bx-refresh"
          iconPos="right"
          class="me-2"
          severity="secondary"
          :disabled=btnClearDisabled
      />
    </div>
    <ProgressSpinner v-else style="width: 50px; height: 50px" strokeWidth="4" fill="transparent"
                     animationDuration=".5s" aria-label="Custom ProgressSpinner" />
</template>

<script>
import {computed, ref} from "vue"
import Toast from 'primevue/toast';
import ConfirmPopup from 'primevue/confirmpopup';
import { useConfirm } from "primevue/useconfirm";
import Button from 'primevue/button';
import Message from 'primevue/message';
import ProgressSpinner from 'primevue/progressspinner';
import {useToast} from "primevue/usetoast";
export default {
  name: "CrudButton",
  props:{
    loading: Boolean,
    btnSaveDisabled: Boolean,
    btnDeleteDisabled: Boolean,
    btnClearDisabled: Boolean,
    recordData:String,
    selectedRecord: {
      type:Object,
      default:() => ({})
    },
    message: {
      type:String,
      default:''
    }
  },
  emits: ["saveClick","deleteClick"],
  components:{ Button,ProgressSpinner,Message,ConfirmPopup,Toast},
  setup(props,{emit}){
    const confirm = useConfirm()
    const toast = useToast()
    let saveBtnLoading = ref(false)
    let deleteBtnLoading = ref(false)
    let clearBtnLoading = ref(false)
    let updateBtnLoading = ref(false)

    function onSaveButtonClick() {
      saveBtnLoading.value = true
      emit('saveClick','save')
      setTimeout(() => {
        saveBtnLoading.value = false
      }, 2000);
    }

    function onUpdateButtonClick(event){
      confirm.require({
        target: event.currentTarget,
        message: 'Are you sure you want to update record '+props.recordData+' ?',
        icon: 'pi pi-exclamation-triangle',
        rejectProps: {
          label: 'Cancel',
          severity: 'secondary',
          outlined: true
        },
        acceptProps: {
          label: 'Update'
        },
        accept: () => {
          updateBtnLoading.value = true
          emit('saveClick','update')
          setTimeout(() => {
            updateBtnLoading.value = false
          }, 2000);
        },
        reject: () => {
          toast.add({ severity: 'error', summary: 'Canceled', detail: 'Cancel update', life: 3000 });
        }
      });
    }

    function onClearButtonClick() {
      clearBtnLoading.value = true
      emit('clearClick')
      setTimeout(() => {
        clearBtnLoading.value = false
      }, 1000);
    }

    function onMessageClose(){
      emit('messageClose')
    }

    function onDeleteButtonClick(event){
      confirm.require({
        target: event.currentTarget,
        message: 'Are you sure you want to delete record '+props.recordData+' ?',
        icon: 'pi pi-exclamation-triangle',
        rejectProps: {
          label: 'Cancel',
          severity: 'secondary',
          outlined: true
        },
        acceptProps: {
          label: 'Delete'
        },
        accept: () => {
          deleteBtnLoading.value = true
          emit('deleteClick','delete')
          setTimeout(() => {
            deleteBtnLoading.value = false
          }, 1000);
        },
        reject: () => {
          toast.add({ severity: 'error', summary: 'Canceled', detail: 'Cancel delete', life: 3000 });
        }
      });
    }

    return{
      isupdate: computed(()=>{
        const update = props.selectedRecord.constructor === Object
        return update
      }),
      saveBtnLoading,
      deleteBtnLoading,
      clearBtnLoading,
      updateBtnLoading,
      onSaveButtonClick,
      onDeleteButtonClick,
      onClearButtonClick,
      onUpdateButtonClick,
      onMessageClose
    }
  }
}
</script>

<style scoped>

</style>