<template>
  <div class="container-xxl flex-grow-1 container-p-y text-start">
    <h4 class="py-3 mb-4">
      <span class="text-muted fw-light">Image /</span> List
    </h4>
    <div class="row">
      <div class="col-md-6">
        <ProgressBar v-if="loading" mode="indeterminate" style="height: 2px"></ProgressBar>
        <div class="card mb-4">
          <div class="card-header d-flex justify-content-between align-items-center">
            <h5 class="mb-0">Upload Image</h5>
            <small class="text-muted float-end">Form image</small>
          </div>
          <div class="card-body">
            <form>
              <div class="mb-3">
                <label class="form-label" for="basic-default-firstname">Image Name</label>
                <input type="text" v-model="dt_image.form.imgname" class="form-control" id="basic-default-firstname" placeholder="Image Name">
              </div>
              <div class="mb-3">
                <label class="col-md-2 col-form-label">Attachment</label>
                <ProgressBar v-if="dt_image.isupload" :value="dt_image.uploadprogress"></ProgressBar>
                <FileUpload
                    name="attachment"
                    :url="fileuploadUrl"
                    @before-send="beforeUpload"
                    @upload="onUpload"
                    @select="onSelect"
                    @progress="onProgress"
                    :multiple="false"
                    :fileLimit="1"
                    accept="image/png,image/jpeg,image/jpg"
                    :maxFileSize="5000000">
                  <template #empty>
                    <span>Drag and drop files to here to upload.</span>
                  </template>
                </FileUpload>
              </div>
              <div class="mb-3">
                <Message v-show="dt_image.message.length>0">{{dt_image.message}}</Message>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <DataView
                :value="dt_image.list"
                :lazy="false"
                paginator
                :rows="5"
                :rowsPerPageOptions="dt_image.pageoptions"
            >
              <template #list="slotProps">
                <div class="flex flex-col">
                  <div v-for="(item, index) in slotProps.items" :key="index" class="bg-light rounded border border-light mb-2">
                    <div class="d-flex align-items-start align-items-sm-center gap-4 p-2 overflow-auto">
                      <Image :src="getImage(item.file_code)" alt="Image" class="d-block rounded" height="100" width="100" preview />
                      <div class="flex-grow-1 row">
                        <div class="col-9 mb-sm-0 mb-2">
                          <h6>{{item.nama_file}} at {{item.created_date}}</h6>
                          <small class="text-primary">Type : {{item.tipe_file}}</small>
                        </div>
                        <div class="col-9 mb-sm-0 mb-2">
                          <h6><b>{{item.image_tag}}</b></h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </DataView>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, computed, onMounted} from "vue";
import {useStore} from "vuex";
import FileUpload from 'primevue/fileupload';
import Message from 'primevue/message'
import DataView from 'primevue/dataview';
import { FilterMatchMode } from '@primevue/core/api'
import DashboardServices from "@/services/dashboard.services";
import ProgressBar from 'primevue/progressbar';
import dashboardServices from "@/services/dashboard.services";
import Image from 'primevue/image';
export default {
  name: "ImageListPage",
  components:{FileUpload,Message,DataView,ProgressBar,Image},
  setup(){
    const store = useStore()
    let fileuploadUrl = ref()
    let urlImage= ref()
    let dt_image = ref({
      httpresponse: 0,
      response:[],
      success: false,
      key:'group_id',
      loading: false,
      isupload: false,
      uploadprogress:0,
      list:[],
      form:{
        imgname:"",
        fileAttachment:""
      },
      message:"",
      rows:0,
      totalrow:0,
      lazy:true,
      lazyParams:[],
      pageoptions:[5, 10, 20, 50, 100],
      filters:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'group_name': {value: '', matchMode: FilterMatchMode.CONTAINS},
      },
      filterDisplay:"row",
      globalFiltersField:['c_fname','c_lname','c_phone','c_email','c_address'],
    })

    function beforeUpload(request){
      let token = dashboardServices.getUserToken();
      request.xhr.setRequestHeader('Authorization', 'Bearer ' + token)
      request.formData.append('paramname',dt_image.value.form.imgname)
      return request
    }

    const onProgress = (event) => {
      dt_image.value.isupload = true
      dt_image.value.uploadprogress = event.progress
    }

    const onUpload = (event) => {
      let response = event.xhr.response
      const obj = JSON.parse(response)
      dt_image.value.uploadMessage = obj.message
      dt_image.value.message = obj.message
      crudImages('read')
    }

    const onSelect = (event) => {
      dt_image.value.isupload = true
      dt_image.value.form.fileAttachment = event.files[0].name
    }

    function onfilterImage(event){
      console.log(event)
    }

    function setupDisplay(data,pagination){
      data.value.rows = pagination.rows
      data.value.totalrow = pagination.records
    }

    function crudImages(action){
      dt_image.value.lazy = true
      store.dispatch('setLoading',true)
      dt_image.value.loading = true
      let data = {
        action:action,
        data: dt_image.value.form,
        params: dt_image.value.lazyParams
      };
      let path = '/dashboard/getData/dataImageList';
      DashboardServices.postData(data,path).then(
          (response)=>{
            dt_image.value.httpresponse = response.data
            if(response.data.status===200){
              dt_image.value.list = response.data.data
              setupDisplay(dt_image,response.data.pagination)
              dt_image.value.message = response.data.message
              dt_image.value.success = true
            }else {
              dt_image.value.success = false
            }
            dt_image.value.loading = false
            store.dispatch('setLoading',false)
          }
      );
    }

    const onPageImage = (event) => {
      dt_image.value.lazyParams = event
      crudImages('read')
    }

    function getImage (code){
      return urlImage.value+code;
    }

    onMounted(() => {
      fileuploadUrl.value = DashboardServices.generateUrl('/dashboard/uploadfile')
      urlImage.value = DashboardServices.generateUrl('/viewimage/')
      crudImages();
    })

    return{
      loading: computed(()=>store.getters.getLoadingStatus),
      dt_image,
      fileuploadUrl,
      beforeUpload,
      onProgress,
      onSelect,
      onUpload,
      crudImages,
      onfilterImage,
      onPageImage,
      getImage
    }
  }
}
</script>

<style scoped>

</style>