<template>
  <div class="container-xxl flex-grow-1 container-p-y text-start">
    <h4 class="py-3 mb-4">
      <span class="text-muted fw-light">Groups /</span> All Groups
    </h4>
    <!-- customers List Table -->
    <div class="row">
      <div class="col-md-12">
        <ProgressBar v-if="loading" mode="indeterminate" style="height: 2px"></ProgressBar>
        <div class="nav-align-top mb-4">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item" role="presentation">
              <button type="button" :class=getTabClass(0) @click="changeTab(0)" role="tab" data-bs-toggle="tab" data-bs-target="#navs-top-profile" aria-controls="navs-top-profile" aria-selected="false" tabindex="-1">
                Group Member List
              </button>
            </li>
          </ul>
          <div class="tab-content">
            <div :class="getTabContentClass(0)" id="navs-top-home" role="tabpanel">
              <div class="card-datatable table-responsive">
                <DataTable
                    :value="dt_groups.list"
                    rowGroupMode="subheader"
                    groupRowsBy="g_name"
                    sortMode="single"
                    sortField="g_name"
                    :sortOrder="1"
                    scrollable
                    scrollHeight="400px"
                    tableStyle="min-width: 50rem">
                  <Column field="g_name" header="Group Name"></Column>
                  <Column field="id" header="GID"></Column>
                  <Column header="Contact Name">
                    <template #body="slotProps">
                      <div class="flex items-center gap-2">
                        <span>{{ slotProps.data.c_fname }} {{ slotProps.data.c_lname }}</span>
                      </div>
                    </template>
                  </Column>
                  <Column field="c_company" header="Company" ></Column>
                  <Column field="bday" header="Birthday" ></Column>
                  <Column header="Email" >
                    <template #body="slotProps">
                      <div class="flex items-center gap-2">
                        <span v-if="slotProps.data.c_email">{{ slotProps.data.c_email }}</span>
                        <span v-else>-</span>
                      </div>
                    </template>
                  </Column>
                  <Column header="Religion">
                    <template #body="slotProps">
                      <div class="flex items-center gap-2">
                        <span v-if="slotProps.data.religion">{{ slotProps.data.religion }}</span>
                        <span v-else>-</span>
                      </div>
                    </template>
                  </Column>
                  <template #groupheader="slotProps">
                    <div class="flex items-center gap-2">
                      <button type="button" class="btn btn-primary text-uppercase">
                        <span class="badge bg-white text-primary me-2">{{ slotProps.data.g_id }}</span>
                        {{ slotProps.data.g_name }}
                      </button>
                    </div>
                  </template>
                  <template #groupfooter="slotProps">
                    <div class="flex justify-end font-bold w-full text-end">Total Customers: {{ calculateCustomerTotal(slotProps.data.g_name) }}</div>
                  </template>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { FilterMatchMode } from '@primevue/core/api'
import DashboardServices from "@/services/dashboard.services";
import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";
import ProgressBar from 'primevue/progressbar'
export default {
  name: "GroupsPage",
  components:{DataTable,Column,ProgressBar},
  setup(){
    const store = useStore()
    let tabactive =  ref(0)
    let dt_groups = ref({
      httpresponse: 0,
      response:[],
      success: false,
      key:'group_id',
      loading: false,
      list:[],
      form:{
        gid:"",
        name:""
      },
      message:"",
      rows:0,
      totalrow:0,
      lazy:true,
      lazyParams:[],
      pageoptions:[5, 10, 20, 50, 100],
      filters:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'group_name': {value: '', matchMode: FilterMatchMode.CONTAINS},
      },
      filterDisplay:"row",
      globalFiltersField:['c_fname','c_lname','c_phone','c_email','c_address'],
    })

    function setupDisplay(data,pagination){
      data.value.rows = pagination.rows
      data.value.totalrow = pagination.records
    }

    function crudGroups(action){
      dt_groups.value.lazy = true
      store.dispatch('setLoading',true)
      dt_groups.value.loading = true
      let data = {
        action:action,
        data: dt_groups.value.form,
        params: dt_groups.value.lazyParams
      };
      let path = '/dashboard/getData/groupmember';
      DashboardServices.postData(data,path).then(
          (response)=>{
            dt_groups.value.httpresponse = response.data
            if(response.data.status===200){
              dt_groups.value.list = response.data.data
              setupDisplay(dt_groups,response.data.pagination)
              dt_groups.value.message = response.data.message
              dt_groups.value.success = true
            }else {
              dt_groups.value.success = false
            }
            dt_groups.value.loading = false
            store.dispatch('setLoading',false)
          }
      );
    }

    const calculateCustomerTotal = (name) => {
      let total = 0;

      if (dt_groups.value.list) {
        for (let customer of dt_groups.value.list) {
          if (customer.g_name === name) {
            total++;
          }
        }
      }

      return total;
    };

    function changeTab(idx){
      tabactive.value = idx;
    }

    function getTabClass(idx){
      if(tabactive.value === idx){
        return "nav-link active"
      }else{
        return "nav-link"
      }
    }

    function getTabContentClass(idx){
      if(tabactive.value === idx){
        return "tab-pane active show"
      }else{
        return "tab-pane fade";
      }
    }

    onMounted(() => {
      crudGroups('read')
    })
    return{
      loading: computed(()=>store.getters.getLoadingStatus),
      getTabClass,
      changeTab,
      getTabContentClass,
      crudGroups,
      dt_groups,
      calculateCustomerTotal
    }
  }
}
</script>

<style scoped>

</style>