import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router/router"
import PrimeVue from 'primevue/config'
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import store from './store/store'
import Aura from '@primevue/themes/aura';

createApp(App)
    .use(router)
    .use(store)
    .use(PrimeVue, {
        theme: {
            preset: Aura,
            options: {
                prefix: 'p',
                darkModeSelector: '',
                cssLayer: false
            }
        }
    })
    .use(ConfirmationService)
    .use(ToastService)
    .mount('#app')
